import React, { Component } from "react"
import axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import LoadingOverlay from 'react-loading-overlay';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var Api_url = process.env.REACT_APP_API_URL;
console.log("API_URL=" + Api_url);

const Image1 = process.env.PUBLIC_URL + '/assets/images/blog/blog-details-img-1.jpg';

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class EventDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            Event: {},
            LoadingFlag: false,
            EventFlag: false,
            PaymentLoadingFlag: false,
            ErrorMsg: '',
            MemberName: '',
            MobileNumber: '',
            EmailId: '',
            Qty: '',
            TotalAmount: 0,
            Order: {},
            AllPlans: [],
            Plans: [],
            isClearable1: true,
            isDisabled1: false,
            isLoading1: false,
            isSearchable1: true,
            TeamOptions: [],
            TeamId: "",
            TeamName: "NA",

            ShowModel: false,
            HideModel: false,
            PlanTitle: "",
            PlanDescription: "",
        };
    }

    async getTeams() {
        console.log("getTeams called ");
        var url = Api_url + `/api/year-wise-lom/options`;
        console.log("url=" + url);
        var postData = {
            "source": "",
            "additional": { "UserInterface": 1 }
        };
        console.log("postData=" + JSON.stringify(postData));
        this.setState({ isLoading1: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));
                var result = res.data.records;
                console.log("result=" + JSON.stringify(result));
                var options = result.map(d => ({
                    value: d.value,
                    label: d.text
                }));
                console.log("options=" + JSON.stringify(options));
                this.setState({ isLoading1: false, TeamOptions: options });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getDetails(id) {
        console.log("getDetails called ");

        // var id = this.state.id;
        console.log("id=" + id);

        if (id > 0) {
            var url = Api_url + `/api/app/events/search`;
            console.log("url=" + url);
            var postData = {
                UserInterface: 1,
                EventCode: id,
            };
            this.setState({ LoadingFlag: true });
            console.log({ postData });
            await axios
                .post(url, postData, axiosConfig)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    console.log("res=" + JSON.stringify(res));

                    this.setState({ LoadingFlag: false });

                    var flag = res.data.flag;
                    var records = res.data.records;
                    console.log({ flag, records });

                    var plans = records.Plans;
                    console.log({ plans });

                    this.setState({ EventFlag: flag, Event: records, AllPlans: plans, Plans: plans });

                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                });
        }
    };

    handleChange(e) {
        // this.setState({ LomId: event.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }

    calculateAmount(e) {
        console.log('calculateAmount called');
        var qty = parseInt(e.target.value);
        console.log({ qty });
        var allPlans = this.state.AllPlans;
        console.log({ allPlans });
        var newPlans = [];
        allPlans.map((item) => {
            var amount = parseInt(item.Amount);
            var totalAmount = qty * amount;
            item.TotalAmount = isNaN(totalAmount) ? 0 : totalAmount;
            console.log({ item });
            newPlans.push(item);
        });
        this.setState({ Qty: qty, Plans: newPlans });

    }

    submitPlan = async (plan) => {
        console.log("submitPlan called ");
        console.log({ plan });
        var url = Api_url + `/api/app/event/order/create`;
        console.log("url=" + url);

        var postData = {
            UserInterface: 2,
            TransactionType: 1,
            Lom: this.state.TeamId,
            MemberName: this.state.MemberName,
            EmailId: this.state.EmailId,
            MobileNo: this.state.MobileNumber,
            Qty: this.state.Qty,
            Plan: plan.PlanId,
            TotalAmount: plan.TotalAmount,
        };
        console.log("postData=" + JSON.stringify(postData));
        this.setState({ SuccessMsg: "", ErrorMsg: "", LoadingFlag: true });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                var flag = res.data.flag;
                console.log({ flag });
                var output = res.data.output;
                console.log({ output });
                console.log({ res });
                this.setState({ LoadingFlag: false });
                if (flag) {
                    var records = res.data.records;
                    console.log({ records });
                    var order = records.Order;
                    console.log({ order });
                    this.setState({ Order: order });
                    this.makePayment(order);
                    // window.location.replace(redirect_url);
                    // this.setState({ SuccessMsg: res.data.output });
                } else {
                    toast.error(output);
                    this.setState({ ErrorMsg: res.data.output });
                }
                // this.setState({ isLoading: false });
                // this.fetchData({ value: this.state.zoneId, label: this.state.zoneName });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    makePayment = async (order) => {
        console.log("makePayment called");
        // ev.preventDefault();
        // var order = this.state.Order;
        var back_url = order.back_url;
        console.log("back_url=" + back_url + ", order=" + JSON.stringify(order));
        this.setState({ PaymentLoadingFlag: true });
        const thisIns = this;
        const options = {
            key: order.rzp_key,
            account_id: order.rzp_account_id,
            order_id: order.rzp_order_id,
            amount: order.rzp_amount,
            name: order.rzp_name,
            currency: "INR",
            description: order.rzp_description,
            image: order.rzp_image,
            theme: {
                color: order.rzp_color,
            },
            prefill: {
                name: order.customer_name,
                email: order.customer_email_id,
                contact: order.customer_mobile_no,
            },
            notes: order.rzp_notes,
            // callback_url: "http://domain.com/#/about/" + this.order_id,
            redirect: false,
            handler: function (response) {
                var razorpay_payment_id = response.razorpay_payment_id;
                console.log("razorpay_payment_id=" + razorpay_payment_id);
                var redirect_url = back_url + '?id=' + razorpay_payment_id;
                console.log({ redirect_url });
                thisIns.setState({ PaymentLoadingFlag: false });
                window.location.replace(redirect_url);
                /*
                thisIns.$session.remove("checkout");
                thisIns.SubmitFlag = true;
                thisIns.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                thisIns.pageRedirect({}, "/payment/success");
                */
            },
            "modal": {
                "ondismiss": function () {
                    console.log('Checkout form closed');
                    thisIns.setState({ PaymentLoadingFlag: false });
                }
            }
        };

        const rzp1 = new window.Razorpay(options);
        // const thisIns = this;
        // var rzp1 = new Razorpay();
        rzp1.open();
    };

    openModel = (title, description) => {
        console.log("openModel called");
        console.log(title);
        console.log(description);
        this.setState({ PlanTitle: title });
        this.setState({ PlanDescription: description });
        this.setState({ ShowModel: true });
        this.setState({ HideModel: false });
    };
    // openModel(data) {
    //     console.log("openModel called");
    //     console.log({ data });
    //     this.setState({ ShowModel: true });
    //     this.setState({ HideModel: false });
    // }
    closeModel() {
        console.log("closeModel called");
        this.setState({ ShowModel: false });
        this.setState({ HideModel: true });
    }

    componentDidMount() {
        console.log("program detail componentDidMount called ");
        window.scrollTo(0, 0);
        let currentLocation = this.props.data;
        currentLocation = currentLocation.toString();
        console.log({ currentLocation });
        let searchParams = currentLocation.split('id=');
        var id = searchParams[1] ? parseInt(searchParams[1]) : '';
        console.log({ searchParams });
        console.log({ id });
        this.getDetails(id);
        this.getTeams();
    }
    render() {
        return (
            <>
                <LoadingOverlay
                    spinner
                    active={this.state.LoadingFlag}
                >
                    <ToastContainer position="top-center" autoClose={false} style={{ width: window.innerWidth - 25 }} />
                    {/* <!--Blog Details Start--> */}
                    <section className="blog-details">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="blog-details__left">

                                        <h3 className="blog-details__title text-center">{this.state.Event.EventName}</h3>
                                        <div className="blog-details__img">
                                            {
                                                this.state.LoadingFlag ? <img src={Image1} alt="" height={500} width={400} /> :
                                                    <img src={this.state.Event.InvitationPath} alt="" height={500} width={400} />
                                            }
                                        </div>
                                        <div className="blog-details__content">
                                            <ul className="list-unstyled blog-details__meta">
                                                <li><a href={onclick = "return: false"}><i className="far fa-user-circle"></i> Incharge By - {this.state.Event.InchargeName} </a>
                                                </li>
                                                {/* <li><a href="blog-details.html"><i className="far fa-comments"></i> 02 Comments</a>
                                            </li> */}
                                            </ul>
                                            {/* <p className="blog-details__text-1"><h3>Description :-</h3> <br />  </p> */}
                                            <p className="blog-details__text-2"><h3>Content :-</h3> <br /> {this.state.Event.EventContent}</p>
                                        </div>
                                        {/* <div className="blog-details__bottom">
                                        <p className="blog-details__tags">
                                            <span>Tags</span>
                                            <a href={onclick = "return: false"}>Cleaning</a>
                                            <a href={onclick = "return: false"}>House</a>
                                        </p>
                                        <div className="blog-details__social-list">
                                            <a href={onclick = "return: false"}><i className="fab fa-twitter"></i></a>
                                            <a href={onclick = "return: false"}><i className="fab fa-facebook"></i></a>
                                            <a href={onclick = "return: false"}><i className="fab fa-pinterest-p"></i></a>
                                            <a href={onclick = "return: false"}><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Container>
                            <Row>
                                <Col md={6} lg={6} className="mt-3">
                                    <label>Select LO</label>
                                    <div className="comment-form__input-box1">
                                        <Select
                                            options={this.state.TeamOptions}
                                            isClearable={this.state.isClearable1}
                                            isSearchable={this.state.isSearchable1}
                                            isDisabled={this.state.isDisabled1}
                                            isLoading={this.state.isLoading1}
                                            onChange={e => {
                                                this.setState({
                                                    TeamId: e.value
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} lg={6} className="mt-3">
                                    <label>Your Name</label>
                                    <div className="comment-form__input-box">
                                        <input
                                            required
                                            className="required"
                                            style={{ width: "100%", height: '50px' }}
                                            type="text"
                                            placeholder="Your name"
                                            value={this.state.MemberName}
                                            name="MemberName"
                                            onChange={this.handleChange.bind(this)}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} lg={6} className="mt-3">
                                    <label>Your mobile number</label>
                                    <div className="comment-form__input-box">
                                        <input
                                            required
                                            className="required"
                                            style={{ width: "100%", height: '50px' }}
                                            type="text"
                                            placeholder="Your mobile number"
                                            value={this.state.MobileNumber}
                                            name="MobileNumber"
                                            onChange={this.handleChange.bind(this)}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} lg={6} className="mt-3">
                                    <label>Your Email ID</label>
                                    <div className="comment-form__input-box">
                                        <input
                                            required
                                            className="required"
                                            style={{ width: "100%", height: '50px' }}
                                            type="text"
                                            placeholder="Your email id"
                                            value={this.state.EmailId}
                                            name="EmailId"
                                            onChange={this.handleChange.bind(this)}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} lg={6} className="mt-3">
                                    <label>Qty</label>
                                    <div className="comment-form__input-box">
                                        <input
                                            required
                                            className="required"
                                            style={{ width: "100%", height: '50px' }}
                                            type="text"
                                            placeholder="Qty"
                                            value={this.state.value}
                                            name="Qty"
                                            onChange={this.calculateAmount.bind(this)}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <div className="container mt-5">
                                <div className="row">
                                    {this.state.Plans.map((plan, index) => (
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6" key={index}>
                                            <div className="plan-card-one__single" align="center">
                                                <div className="plan-card-one__content">
                                                    <h1 className="plan-card-one__title">{plan.AmountTxt}</h1>
                                                    <p> {plan.TotalAmount} </p>
                                                    <br />
                                                    <div className="plan-card-one__btn-box"> </div>
                                                    {/* <br/>
                                                 <div className="plan-card-one__btn-box"> </div> */}

                                                    <div align="center">
                                                        <p className="btn plan-card-btn1" onClick={() => this.openModel(plan.PlanName, plan.Description)}>Plan Details</p>
                                                        &nbsp; &nbsp;
                                                        <p
                                                            className="plan-card-btn"
                                                            id="con_submit"
                                                            onClick={() => this.submitPlan(plan)}
                                                        >
                                                            <span>Pay now</span>
                                                        </p>
                                                        {/* <button
                                                        className="theme-btn"
                                                        id="con_submit"
                                                        onClick={() => this.submitPlan(plan)}
                                                    >
                                                        <span>Pay now</span>
                                                    </button> */}
                                                    </div>
                                                    <div className="plan-card-one__tag">
                                                        <p> &nbsp; &nbsp; {plan.PlanName} &nbsp; &nbsp; </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Modal show={this.state.ShowModel} aria-labelledby="contained-modal-title-vcenter" centered>
                                <Modal.Header>
                                    <Modal.Title> <h5>{this.state.PlanTitle}</h5> </Modal.Title>
                                    <Button variant="secondary" onClick={this.closeModel.bind(this)}>
                                        X
                                    </Button>
                                </Modal.Header>
                                <Modal.Body>
                                    {/* <p>{this.state.PlanDescription}</p> */}
                                    <div dangerouslySetInnerHTML={{ __html: this.state.PlanDescription }}></div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="info" onClick={this.closeModel.bind(this)}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            {/* <Row className="mt-4">
                            {this.state.Plans.map((plan, index) => (
                                <Col md={4} lg={4} key={index}>
                                    <p> {plan.PlanName} </p>
                                    <p> {plan.Description} </p>
                                    <p> {plan.AmountTxt} / person </p>
                                    <p> {plan.TotalAmount} </p>
                                    <button
                                        className="theme-btn"
                                        id="con_submit"
                                        onClick={() => this.submitPlan(plan)}
                                    >
                                        <span>Pay now</span>
                                    </button>
                                </Col>
                            ))}
                        </Row> */}
                            <Row className="mt-3 text-center">
                                <Col md={12} lg={12} >
                                    <p className="text-success">{this.state.SuccessMsg}</p>
                                    <p className="text-danger">{this.state.ErrorMsg}</p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </LoadingOverlay>
                {/* <!--Blog Details End--> */}
            </>
        )
    }
}