import React, { Component } from "react"

export default class ScrollTop extends Component {
    scrollToTop(){
        console.log("scrollToTop called");
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <button onClick={this.scrollToTop} style={{border:"none"}} data-target="html" className="scroll-to-target scroll-to-top"> <i className="fa fa-angle-up"> </i> </button>
            </>
        )
    }
}

