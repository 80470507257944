import React, { Component } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default class PresidentDetails extends Component {
    render() {
        const member = this.props.Records;
        return (
            <>
                <div className="blog-details__left" style={{ marginLeft: "20px", marginRight: "20px" }}>
                    {/* {this.props.Records.map((item, index) => ( */}
                    <div className="comment-one">
                        <h3 className="comment-one__title">President</h3>
                        <div className="comment-one__single">
                            <div className="comment-one__image">
                                <LazyLoadImage effect="blur" src={member.ProfilePic} alt="" />
                            </div>
                            <div className="comment-one__content">
                                <br />
                                <h3>{member.MemberName}</h3>
                                <br />
                                <p>
                                    Mauris non dignissim purus, ac commodo diam. Donec sit amet lacinia nulla.
                                    Aliquam quis purus in justo pulvinar tempor. Aliquam tellus nulla,
                                    sollicitudin at euismod.
                                </p>
                                <Link to={"/profile?id=" + member.MemberId} className="thm-btn comment-one__btn"> View </Link>
                                {/* <a href="blog-details.html" className="thm-btn comment-one__btn">Reply</a> */}
                            </div>
                        </div>
                    </div>

                    {/* ))} */}
                </div>
            </>
        )
    }
}