import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class OrganizationList extends Component {
    render() {
        return (
            <>
                <section className="local-org-page">
                    <div className="container">
                        <div className="row">
                            {this.props.Records.map((item, index) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6" key={index}>
                                    <div className="local-org-one__single">
                                        <div className="local-org-one__content">
                                            <h3 className="local-org-one__title">
                                                <Link to={"/local-organization/detail?id=" + item.LomId}>{item.LomDisplayTxt}</Link>
                                            </h3>
                                            <div className="local-org-one__btn-box">
                                                {/* <i className="fa fa-angle-right"></i> */}
                                            </div>
                                            <p align="center">
                                                Board - {item.BoardMembersCount} &nbsp; &nbsp; &nbsp; &nbsp; Member - {item.MembersCount}
                                            </p>
                                            <div className="local-org-one__tag">
                                                <p> LOM </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}