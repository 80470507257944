import React, { Component } from "react";
import {Link} from "react-router-dom"

const Image1 = process.env.PUBLIC_URL + '/assets/images/backgrounds/page-header-bg.jpg';

export default class PageHeader extends Component {
    render() {
        return (
            <>
                {/* <!--Page Header Start--> */}
                <section className="page-header">
                    <div className="page-header-bg" style={{ backgroundImage: `url(${Image1})` }}>
                     {/* style="background-image: url(assets/images/backgrounds/page-header-bg.jpg)"> */}
                    </div>
                    <div className="page-header-bubble"><img src="assets/images/shapes/page-header-bubble.png" alt=""/></div>
                    <div className="container">
                        <div className="page-header__inner">
                            <ul className="thm-breadcrumb list-unstyled">
                                <li>
                                    <Link to="/">Home</Link>
                                    {/* <a href="index.html">Home</a> */}
                                    </li>
                                <li><span>/</span></li>
                                <li>{this.props.pageTitle}</li>
                            </ul>
                            <h2>{this.props.pageText}</h2>
                        </div>
                    </div>
                </section>
                {/* <!--Page Header End--> */}
            </>
        )
    }
}