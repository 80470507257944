import React, { Component } from "react";
import axios from "axios";
import LoadingGif from "../../loading/loading2.gif";

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
}

export default class ContactPage extends Component {
    constructor() {
        super();
        // this.state = {
        //     Records1: [],
        //     Total1: 0,
        //     LoadingFlag1: false,

        //     Records2: [],
        //     LoadingFlag2: false,
        //     RecordsFlag2: false,

        //     Records: [],
        //     LoadingFlag: false
        // };

        this.state = {
            LoadingFlag: false,
            Blocks: "",

            MobileNo: "",
            MailId: "",
            Address: "",
            Mission: "",
            Vision: "",
            Creed: "",
            Facebook: "",
            Twitter: "",
            Instagram: "",
            LinkedIn: "",
            MembersOath: "",
            OfficersOath: "",
            FoundersPerspective: "",
            CompanyName: "",
        };
    }

    async getAllRecords1() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'mail_id',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag1: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag1: false, Records1: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords2() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'address',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag2: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag: false });

                var flag = res.data.flag;
                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag2: false, RecordsFlag2: flag, Records2: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getDetails() {
        console.log("getDetails called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'mobile_no',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag: false });

                var flag = res.data.flag;
                var records = res.data.records;
                console.log({ flag, records });

                this.setState({ RecordsFlag: flag, Records: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };

    async pageDetails() {
        console.log("pageDetails called ");
        var url = Api_url + `/api/app/app-page/show`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            PageName: 'blocks',
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var flag = res.data.flag;
                var blocks = res.data.records['blocks'];

                console.log({ flag, blocks });

                this.setState({ LoadingFlag: false, Blocks: blocks });

                // var settingsvalues = records.SettingsValues[0].SettingsValuesContent;
                // console.log(settingsvalues);
                var mobile_no = blocks.SettingsValues['mobile_no'].SettingsValuesContent;
                var mail_id = blocks.SettingsValues['mail_id'].SettingsValuesContent;
                var address = blocks.SettingsValues['address'].SettingsValuesContent;
                var mission = blocks.SettingsValues['mission'].SettingsValuesContent;
                var vision = blocks.SettingsValues['vision'].SettingsValuesContent;
                var creed = blocks.SettingsValues['creed'].SettingsValuesContent;
                var facebook = blocks.SettingsValues['facebook'].SettingsValuesContent;
                var twitter = blocks.SettingsValues['twitter'].SettingsValuesContent;
                var instagram = blocks.SettingsValues['instagram'].SettingsValuesContent;
                var linkedin = blocks.SettingsValues['linkedin'].SettingsValuesContent;
                var members_oath = blocks.SettingsValues['members_oath'].SettingsValuesContent;
                var officers_oath = blocks.SettingsValues['officers_oath'].SettingsValuesContent;
                var founders_perspective = blocks.SettingsValues['founders_perspective'].SettingsValuesContent;
                var company_name = blocks.SettingsValues['company_name'].SettingsValuesContent;

                this.setState({
                    MobileNo: mobile_no,
                    MailId: mail_id,
                    Address: address,
                    Mission: mission,
                    Vision: vision,
                    Creed: creed,
                    Facebook: facebook,
                    Twitter: twitter,
                    Instagram: instagram,
                    LinkedIn: linkedin,
                    MembersOath: members_oath,
                    OfficersOath: officers_oath,
                    FoundersPerspective: founders_perspective,
                    CompanyName: company_name,
                });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };

    componentDidMount() {
        console.log("componentDidMount called ");
        // this.getDetails();
        // this.getAllRecords1();
        // this.getAllRecords2();

        this.pageDetails();
    }

    render() {
        return (
            <>
                {/* <!--Contact Page One Details Start--> */}
                <section className="contact-page-one-details">
                    <div className="container">
                        <ul className="list-unstyled contact-page-one-details__list">
                            <li>
                                <div className="contact-page-one-details__icon">
                                    <span className="icon-phone-call"></span>
                                </div>
                                <div className="contact-page-one-details__content-box">
                                    <p className="contact-page-one-details__content">
                                        {
                                            this.state.LoadingFlag ?
                                                <img src={LoadingGif} alt="" width={140} /> :
                                                this.state.MobileNo !== "" ?
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.MobileNo }} /> : "NA"
                                        }
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="contact-page-one-details__icon">
                                    <span className="icon-message"></span>
                                </div>
                                <div className="contact-page-one-details__content-box">
                                    <p className="contact-page-one-details__content">
                                        {
                                            this.state.LoadingFlag ?
                                                <img src={LoadingGif} alt="" width={140} /> :
                                                this.state.MailId !== "" ?
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.MailId }} /> : "NA"
                                        }
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="contact-page-one-details__icon">
                                    <span className="icon-location"></span>
                                </div>
                                <div className="contact-page-one-details__content-box">
                                    <p className="contact-page-one-details__content">
                                        {
                                            this.state.LoadingFlag ?
                                                <img src={LoadingGif} alt="" width={140} /> :
                                                this.state.Address !== "" ?
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.Address }} /> : "NA"
                                        }
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                {/* <!--Contact Page One Details End--> */}
            </>
        )
    }
}