import React, { Component } from "react";

export default class ErrorPage extends Component {
    render() {
        return (
            <>
                <h2>404 Error</h2>
            </>
        );
    }
}