import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import OrganizationList from "../../components/local_organization/OrganizationList";
import axios from "axios";
import LoadingContent from "../../components/LoadingContent";

var Api_url = process.env.REACT_APP_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class LocalOrganizationList extends Component {
    constructor() {
        super();
        this.state = {
            Records: [],
            LoadingFlag: false,
            TotalRecords: 0,
        };
    }
    async getAllRecords() {
        console.log("getRecords called ");
        var url = Api_url + `/api/app/yearwise-team/get_lom_members_count`;
        console.log("url=" + url);
        var postData = {
            "UserInterface": 1,
            "Year": 4,
        };
        console.log("postData=" + JSON.stringify(postData));
        this.setState({ LoadingFlag: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));
                var result = res.data.records.TableData;
                var n1 = result.length;
                console.log("n1=" + n1);
                console.log("result=" + JSON.stringify(result));
                this.setState({ LoadingFlag: false, TotalRecords: n1, Records: result });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called");
        window.scrollTo(0, 0);
        this.getAllRecords();
    }
    render() {
        return (
            <>
                <Helmet>
                    <title> Local Organization </title>
                    {/* <meta name='description' content='Local Organization' /> */}
                </Helmet>
                <Layout>
                    <PageHeader pageTitle="Local Organization" pageText="Local Organization" />
                    {
                        this.state.LoadingFlag ? <LoadingContent /> : <OrganizationList Records={this.state.Records} />
                    }
                </Layout>
            </>
        )
    }
}