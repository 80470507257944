import React, { Component } from "react";
import { Helmet } from "react-helmet";
// import InformationSection from "../../components/InformationSection";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import LOMPage from "../../components/team/LOMPage";
import LoadingGif from "../../loading/loading2.gif";

import Select from "react-select";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";

var Api_url = process.env.REACT_APP_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class LOMGoverningBoard extends Component {

    constructor() {
        super();
        this.state = {
            members: [],
            isClearable1: true,
            isDisabled1: false,
            isLoading1: false,
            isSearchable1: true,
            TeamOptions: [],
            TeamId: 1,
            TeamName: "NA",
            LoadingFlag: false,
            TotalRecords: 0,
        };
    }

    async fetchData(e) {
        console.log("fetchData called ");
        console.log("e = ", e);
        var TeamId = e.value;
        this.setState({ TeamId: e.value, TeamName: e.label });
        var url = Api_url + `/api/app/members/lists`;
        console.log("url=" + url + ", TeamId=" + TeamId);
        var postData = {
            "UserInterface": 1,
            "LocalOrganizationName": TeamId
        };
        console.log("postData=" + JSON.stringify(postData));
        this.setState({ LoadingFlag: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));
                var result = res.data.records.TableData;
                var n2 = result.length;
                console.log("n2=" + n2);
                console.log("result=" + JSON.stringify(result));
                var TeamName = e.label;
                console.log("TeamName = ", TeamName);
                this.setState({ LoadingFlag: false, TotalRecords: n2, members: result, TeamName: TeamName });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getTeams() {
        console.log("getTeams called ");
        var url = Api_url + `/api/year-wise-lom/options`;
        console.log("url=" + url);
        var postData = {
            "source": "",
            "additional": { "UserInterface": 1 }
        };
        console.log("postData=" + JSON.stringify(postData));
        this.setState({ isLoading1: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));
                var result = res.data.records;
                console.log("result=" + JSON.stringify(result));
                var options = result.map(d => ({
                    value: d.value,
                    label: d.text
                }));
                console.log("options=" + JSON.stringify(options));
                this.setState({ TeamOptions: options });
                this.setState({ isLoading1: false });
                this.fetchData({ value: this.state.TeamId, label: this.state.TeamName });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };
    componentDidMount() {
        console.log("componentDidMount called");
        window.scrollTo(0, 0);
        this.getTeams();
    }
    render() {
        return (
            <>
                <Helmet>
                    <title> LOM Governing Board </title>
                    {/* <meta name='description' content='Home' /> */}
                </Helmet>
                <Layout>
                    <PageHeader pageTitle="Team" pageText="LOM Governing Board" />

                    <h3> {this.state.hello} </h3>
                    <Container>
                        <br /><br /><br />
                        <Row className=" align-items-center">
                            <Col md={6} lg={6} >
                                Select Local Organization Name
                            </Col>
                            <Col md={6} lg={6} style={{ zIndex: 10 }}>
                                <Select
                                    options={this.state.TeamOptions}
                                    onChange={this.fetchData.bind(this)}
                                    isClearable={this.state.isClearable1}
                                    isSearchable={this.state.isSearchable1}
                                    isDisabled={this.state.isDisabled1}
                                    isLoading={this.state.isLoading1}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="text-left"><h5><span>Team Name :- </span> {this.state.TeamName} </h5> </Col>
                        </Row>
                    </Container>
                    {this.state.LoadingFlag ?
                    <div className="row" >
                        <img src={LoadingGif} alt="Loading" height="250px" width="80px" />
                    </div> :
                        (this.state.TotalRecords) > 0 ?
                            <LOMPage members={this.state.members} /> : <h3 className="text-center" style={{ marginTop: "40px", marginBottom: "100px" }}>No Records Found</h3>
                    }
                    {/* <div className="row" >
                        <img src={LoadingGif} alt="Loading" height="250px" width="80px" />
                    </div> */}
                    {/* <InformationSection /> */}
                </Layout>
            </>
        )
    }
}
