import React, { Component } from "react";
import axios from "axios";
import LoadingGif from "../../loading/loading2.gif";
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
// import GalleryPageContent from "./GalleryPageContent";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};

export default class GalleryPage extends Component {
    constructor() {
        super();
        this.state = {
            Records: [],
            LoadingFlag: false,
            TotalRecords: 0,
            ImagePath: '',
            isOpen: false,
        }
    };

    async getAllRecords() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            SettingsName: "gallery",
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = result.length;
                console.log("n1=" + n1);

                this.setState({ LoadingFlag: false, Records: result, TotalRecords: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        this.getAllRecords();
    }
    render() {
        const { ImagePath, isOpen } = this.state;
        return (
            <>
                {/* <!--Gallery Page Start--> */}
                <section className="gallery-page">
                    <div className="container">
                        <div className="row">
                            {this.state.LoadingFlag ? <img src={LoadingGif} alt="Loading" height="250px" width="100px" /> :
                                (this.state.TotalRecords > 0 ?
                                    this.state.Records.map((item, index) => (
                                        <div className="col-sm-8 col-xl-4 col-lg-6 col-md-6" key={index}>
                                            <div>
                                                {isOpen && (
                                                    <Lightbox
                                                        mainSrc={ImagePath}
                                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                                    />
                                                )}
                                                {console.log("isOpen = ", isOpen)}
                                                {/* <GalleryPageContent image={item.FilePath} /> */}
                                                <LazyLoadImage effect="blur" src={item.FilePath} alt="" style={{ width: 350, height: 250, borderRadius: 10 }} onClick={() => this.setState({ isOpen: true, ImagePath: item.FilePath })} />

                                                <p className="text-center">{item.SettingsValuesTitle}</p>
                                            </div>
                                        </div>
                                    ))
                                    : <p className="text-center">NA</p>
                                )
                            }
                        </div>
                    </div>
                </section>
                {/* <!--Gallery Page End--> */}
            </>
        )
    }
}