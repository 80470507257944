import React, { Component } from "react";
import RoundGif from "../loading/round.gif"

console.log('RoundGif=' + RoundGif);

export default class LoadingContent extends Component {
    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <img src={RoundGif} alt="" width="300px" />
                    </div>
                </div>
            </div >
        )
    }
};
