import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadingGif from "../../loading/loading2.gif";
import RoundGif from "../../loading/round.gif";

const Image1 = process.env.PUBLIC_URL + '/assets/images/backgrounds/welcome-one-bg.png';

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
}

export default class WelcomeSection extends Component {
    constructor() {
        super();
        this.state = {
            Total1: 0,
            LoadingFlag1: false,
            PresidentLogo: {},

            Total2: 0,
            LoadingFlag2: false,
            PresidentFullImage: {},

            Total3: 0,
            LoadingFlag3: false,
            PresidentMessage: {},

            Records: [],
            RecordsFlag: false,
            LoadingFlag: false
        };
    }

    async getDetails() {
        console.log("getDetails called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'mobile_no',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var flag = res.data.flag;
                var records = res.data.records;
                console.log({ flag, records });

                this.setState({ LoadingFlag: false, RecordsFlag: flag, Records: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };
    async getAllRecords1() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            SettingsName: "president_logo",
        };
        this.setState({ LoadingFlag1: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = Object.keys(result).length;
                console.log({ n1 });
                console.log({ result });

                this.setState({ LoadingFlag1: false, PresidentLogo: result, Total1: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords2() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            SettingsName: "president_full_image",
        };
        this.setState({ LoadingFlag2: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = Object.keys(result).length;
                console.log({ n1 });
                console.log({ result });

                this.setState({ LoadingFlag2: false, PresidentFullImage: result, Total2: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords3() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            SettingsName: "president_message",
        };
        this.setState({ LoadingFlag3: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = Object.keys(result).length;
                console.log({ n1 });
                console.log({ result });

                this.setState({ LoadingFlag3: false, PresidentMessage: result, Total3: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        // this.getDetails();
        // this.getAllRecords1();
        // this.getAllRecords2();
        // this.getAllRecords3();
    }

    render() {
        return (
            <>
                {/* <!--Welcome One Start--> */}
                <section className="welcome-one">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-xl-6 col-lg-6">
                                <div className="welcome-one__left">
                                    <div className="welcome-one__bg" style={{ background: `url(${Image1})` }}>
                                        {/* style="background-image: url(assets/images/backgrounds/welcome-one-bg.png);"> */}
                                    </div>
                                    <div className="welcome-one__main-box wow fadeInUp" data-wow-delay="100ms"
                                        data-wow-duration="2500ms">
                                        <div className="welcome-one__circle-one"></div>
                                        <div className="welcome-one__img-1">
                                            {this.props.LoadingFlag ? <img src="assets/images/resources/welcome-one-img-1.png" alt="" /> :
                                                this.props.PresidentImage !== "" ?
                                                    <img src={this.props.PresidentImage} style={{ width: 400, height: 600,borderRadius:"10px" }} alt="" /> :
                                                    <img src="assets/images/resources/welcome-one-img-1.png" alt="" />
                                            }
                                            <div className="welcome-one__small-img-1 float-bob-x">
                                                {this.props.LoadingFlag ?
                                                    <img src={RoundGif} alt="" style={{ height: "200px", width: "200px", borderRadius: "100%" }} /> :
                                                    this.props.PresidentLogo !== ""  ?
                                                        <img src={this.props.PresidentLogo} style={{ width: 210, height: 210 }} alt="" /> :
                                                        <img src="assets/images/resources/welcome-one-small-img-1.jpg" alt="" />
                                                }
                                            </div>
                                            <div className="welcome-one__small-img-2 float-bob-y">
                                                {/* {this.state.LoadingFlag1 ?
                                                    <img src={RoundGif} alt="" style={{ height: "200px", width: "200px", borderRadius: "100%" }} /> :
                                                    this.state.Total1 > 0 ?
                                                        <img src={this.state.PresidentLogo.FilePath} style={{ width: 200, height: 200 }} alt="" /> :
                                                        <img src="assets/images/resources/welcome-one-small-img-1.jpg" alt="" />
                                                } */}
                                                {/* <img src="assets/images/rose.jpg" style={{ width: 200, height: 200 }} alt="" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="welcome-one__right">
                                    <div className="section-title text-left">
                                        <span className="section-title__tagline">Our Introduction</span>
                                        <h2 className="section-title__title">Our Zone President</h2>
                                    </div>
                                    {/* <p className="welcome-one__text-1">Lorem ipsum is simply free text dolor sit am adipi we help
                                        you ensure everyone.</p> */}
                                    <div className="welcome-one__text-2 ">
                                        {this.props.LoadingFlag ?
                                            <img src={LoadingGif} alt="" style={{ height: "200px", width: "400px" }} /> :
                                            this.props.PresidentMessage !== "" ? <div style={{ overflow: "hidden", height: "18em" }} dangerouslySetInnerHTML={{ __html: this.props.PresidentMessage }} /> : <h4>Comming Soon . . .</h4>
                                        }
                                    </div>
                                    <div className="welcome-one__btn-call-box">
                                        <div className="welcome-one__btn-box">
                                            <Link to="/about" className="thm-btn welcome-one__btn">About us <i className="fa fa-angle-right"></i></Link>
                                        </div>
                                        <div className="welcome-one__call-box">
                                            <div className="welcome-one__call-icon">
                                                <span className="icon-phone-call"></span>
                                            </div>
                                            <div className="welcome-one__call-content">
                                                <p className="welcome-one__call-sub-title">Call Anytime</p>
                                                <h5 className="welcome-one__call-number">
                                                    {/* {
                                                        this.state.LoadingFlag ?
                                                            <img src={LoadingGif} alt="" style={{ height: "80px", width: "160px" }} /> :
                                                            this.state.RecordsFlag ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.Records.SettingsValuesContent }} /> : "NA"
                                                    } */}
                                                    9803958473
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--Welcome One End--> */}
            </>
        )
    }
}