import React, { Component } from "react";
import axios from "axios";
import LoadingGif from "../../loading/loading2.gif";

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
}


export default class GetToKnow extends Component {
    constructor() {
        super();
        this.state = {
            Total2: 0,
            LoadingFlag2: false,
            PresidentFullImage: {},

            Total3: 0,
            LoadingFlag3: false,
            PresidentMessage: {},
        };
    }

    async getAllRecords2() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            SettingsName: "president_full_image",
        };
        this.setState({ LoadingFlag2: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = Object.keys(result).length;
                console.log({ n1 });
                console.log({ result });

                this.setState({ LoadingFlag2: false, PresidentFullImage: result, Total2: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords3() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            SettingsName: "president_message",
        };
        this.setState({ LoadingFlag3: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = Object.keys(result).length;
                console.log({ n1 });
                console.log({ result });

                this.setState({ LoadingFlag3: false, PresidentMessage: result, Total3: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        // this.getAllRecords2();
        // this.getAllRecords3();
    }

    render() {
        return (
            <>
                {/* <!--Get To Know Start--> */}
                <section className="get-to-know">
                    <div className="get-to-know-bubble float-bob-x">
                        <img src="assets/images/shapes/get-to-know-bubble.png" alt="" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="get-to-know__left">
                                    <div className="get-to-know__img wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                                        {/* <div className="get-to-know-img"> */}
                                        {this.state.LoadingFlag2 ?
                                            <img src="assets/images/resources/get-to-know-img-1.jpg" alt="" />
                                            :
                                            this.state.Total2 > 0 ?
                                                <img src={this.state.PresidentFullImage.FilePath} className="choose-img_one col-sm-6" alt="" /> :
                                                <img src="assets/images/resources/get-to-know-img-1.jpg" alt="" />
                                        }
                                        <div className="get-to-know-shape-1"></div>
                                        <div className="get-to-know-shape-2"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="get-to-know__right">
                                    <div className="section-title text-left">
                                        <span className="section-title__tagline">Benefits</span>
                                        <h3 className="section-title__title">Get to Know About Us</h3>
                                    </div>
                                    <div className="get-to-know__text">
                                        {this.state.LoadingFlag3 ? <img src={LoadingGif} alt="" style={{ height: "200divx", width: "400px" }} /> :
                                            this.state.Total3 > 0 ? <div className="text-justify" style={{ overflow: "hidden", height: "18em" }} dangerouslySetInnerHTML={{ __html: this.state.PresidentMessage.SettingsValuesContent }} /> :
                                                "Comming Soon"
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--Get To Know End--> */}
            </>
        )
    }
}