import React, { Component } from "react";
import ServiceSectionContent from "./ServiceSectionContent";

export default class ServiceSection extends Component {

    // constructor() {
    //     super();
    //     this.state = {
    //         Records1: [],
    //         LoadingFlag1: false,
    //         RecordsFlag1: false,

    //         Records2: [],
    //         LoadingFlag2: false,
    //         RecordsFlag2: false,

    //         Records3: [],
    //         LoadingFlag3: false,
    //         RecordsFlag3: false,
    //     };
    // }

    // componentDidMount() {
    //     console.log("componentDidMount called ");
    //     this.getDetails1();
    //     this.getDetails2();
    //     this.getDetails3();
    // }
    render() {
        return (
            <>
                {/* <!--Services One Start--> */}
                <section className="services-one">
                    <div className="container">
                        <div className="section-title text-center">
                            <span className="section-title__tagline">What We’re Saying</span>
                            <h2 className="section-title__title">To know about us</h2>
                        </div>
                    <ServiceSectionContent FoundersPerspective={this.props.FoundersPerspective} MembersOath={this.props.MembersOath} OfficersOath={this.props.OfficersOath} LoadingFlag={this.props.LoadingFlag} />
                    </div>
                </section>
                {/* <!--Services One End--> */}
            </>
        )
    }
}