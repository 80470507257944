import React, { Component } from "react"
import { Helmet } from "react-helmet"
import EventDetailPage from "../../components/event/EventDetailPage"
// import InformationSection from "../../components/InformationSection"
import Layout from "../../components/Layout"
import PageHeader from "../../components/PageHeader"

export default class EventDetail extends Component {
    componentDidMount() {
        console.log("EventDetail componentDidMount called ");
        let currentLocation = window.location.toString();
        console.log({ currentLocation });
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>EventDetail</title>
                    {/* <meta name='description' content='Home' /> */}
                </Helmet>
                <Layout>
                    <PageHeader pageTitle="Event" pageText="Event Detail" />
                    <EventDetailPage data={window.location} />
                    {/* <InformationSection /> */}
                </Layout>
            </>
        )
    }
}