import React, { Component } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import RoundGif from "../loading/round.gif";
import LoadingGif from "../loading/loading2.gif";

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
}

var CurrentYear = new Date().getFullYear();

const Image1 = process.env.PUBLIC_URL + '/assets/images/shapes/site-footer-shape-1.png';
const blockTitleHeart = process.env.PUBLIC_URL + '/assets/images/shapes/heart-2-1.png';

export default class Footer extends Component {
    constructor() {
        super();
        // this.state = {
        //     Records1: [],
        //     Total1: 0,
        //     LoadingFlag1: false,

        //     PresidentLogo: {},
        //     Total2: 0,
        //     LoadingFlag2: false,

        //     Records3: [],
        //     Total3: 0,
        //     LoadingFlag3: false,

        //     Records4: [],
        //     Total4: 0,
        //     LoadingFlag4: false,

        //     Records5: [],
        //     Total5: 0,
        //     LoadingFlag5: false,

        //     Records6: [],
        //     Total6: 0,
        //     LoadingFlag6: false,

        //     Records7: [],
        //     Total7: 0,
        //     LoadingFlag7: false,

        //     Records8: [],
        //     Total8: 0,
        //     LoadingFlag8: false,

        //     Records: [],
        // };

        this.state = {
            LoadingFlag: false,
            Blocks: "",
            Logo: "",

            MobileNo: "",
            MailId: "",
            Address: "",
            Mission: "",
            Vision: "",
            Creed: "",
            Facebook: "",
            Twitter: "",
            Instagram: "",
            LinkedIn: "",
            MembersOath: "",
            OfficersOath: "",
            FoundersPerspective: "",
            CompanyName: "",
            PresidentLogo: "",
        };
    }

    async getAllRecords1() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'mail_id',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag1: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag1: false, Records1: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords2() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            SettingsName: "president_logo",
        };
        this.setState({ LoadingFlag2: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = Object.keys(result).length;
                console.log({ n1 });
                console.log({ result });

                this.setState({ LoadingFlag2: false, PresidentLogo: result, Total2: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords3() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'facebook',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag3: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag3: false, Records3: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords4() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'twitter',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag4: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag4: false, Records4: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords5() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'instagram',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag5: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag5: false, Records5: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords6() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'linkedin',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag6: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag6: false, Records6: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords7() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'company_name',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag7: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag7: false, Records7: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords8() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'address',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag8: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag: false });

                var flag = res.data.flag;
                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag8: false, RecordsFlag8: flag, Records8: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getDetails() {
        console.log("getDetails called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'mobile_no',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag: false });

                var flag = res.data.flag;
                var records = res.data.records;
                console.log({ flag, records });

                this.setState({ RecordsFlag: flag, Records: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };

    async pageDetails() {
        console.log("pageDetails called ");
        var url = Api_url + `/api/app/app-page/show`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            PageName: 'header',
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var flag = res.data.flag;
                var blocks = res.data.records['blocks'];
                var president_logo = res.data.records['president_logo'];

                console.log({ flag, blocks });
                console.log({ president_logo });

                this.setState({ LoadingFlag: false, Blocks: blocks, PresidentLogo: president_logo });

                // var settingsvalues = records.SettingsValues[0].SettingsValuesContent;
                // console.log(settingsvalues);
                var mobile_no = blocks.SettingsValues['mobile_no'].SettingsValuesContent;
                var mail_id = blocks.SettingsValues['mail_id'].SettingsValuesContent;
                var address = blocks.SettingsValues['address'].SettingsValuesContent;
                var mission = blocks.SettingsValues['mission'].SettingsValuesContent;
                var vision = blocks.SettingsValues['vision'].SettingsValuesContent;
                var creed = blocks.SettingsValues['creed'].SettingsValuesContent;
                var facebook = blocks.SettingsValues['facebook'].SettingsValuesContent;
                var twitter = blocks.SettingsValues['twitter'].SettingsValuesContent;
                var instagram = blocks.SettingsValues['instagram'].SettingsValuesContent;
                var linkedin = blocks.SettingsValues['linkedin'].SettingsValuesContent;
                var members_oath = blocks.SettingsValues['members_oath'].SettingsValuesContent;
                var officers_oath = blocks.SettingsValues['officers_oath'].SettingsValuesContent;
                var founders_perspective = blocks.SettingsValues['founders_perspective'].SettingsValuesContent;
                var company_name = blocks.SettingsValues['company_name'].SettingsValuesContent;

                var logo = president_logo.SettingsValues['president_logo'].FilePath;

                this.setState({
                    MobileNo: mobile_no,
                    MailId: mail_id,
                    Address: address,
                    Mission: mission,
                    Vision: vision,
                    Creed: creed,
                    Facebook: facebook,
                    Twitter: twitter,
                    Instagram: instagram,
                    LinkedIn: linkedin,
                    MembersOath: members_oath,
                    OfficersOath: officers_oath,
                    FoundersPerspective: founders_perspective,
                    CompanyName: company_name,
                    Logo: logo,
                });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };
    componentDidMount() {
        console.log("componentDidMount called ");
        // this.getAllRecords1();
        // this.getAllRecords2();
        // this.getAllRecords3();
        // this.getAllRecords4();
        // this.getAllRecords5();
        // this.getAllRecords6();
        // this.getAllRecords7();
        // this.getAllRecords8();
        // this.getDetails();

        this.pageDetails();
    }
    render() {
        return (
            <>
                {/* <!--Site Footer Start--> */}
                <footer className="site-footer">
                    <div className="site-footer-shape-1" style={{ backgroundImage: `url(${Image1})` }}>
                        {/* style="background-image: url(assets/images/shapes/site-footer-shape-1.png);"> */}
                    </div>
                    <div className="site-footer-shape-two">
                        {/* <img src="assets/images/shapes/site-footer-shape-2.png" alt="" /> */}
                    </div>
                    <div className="site-footer__top">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                                    <div className="footer-widget__column footer-widget__about">
                                        <h3 className="footer-widget__title">
                                            <div dangerouslySetInnerHTML={{ __html: this.state.CompanyName }} />
                                        </h3>
                                        <div className="footer-widget__about-text-box" style={{ marginLeft: "40px" }}>
                                            <Link to="/">
                                                {
                                                    this.state.LoadingFlag ?
                                                        <img src={RoundGif} alt="" style={{ height: "100px", width: "100px", borderRadius: "100%" }} /> :
                                                        this.state.Logo !== "" ?
                                                            <img src={this.state.Logo} style={{ width: "110px", height: "110px", borderRadius: "100px" }} alt="" /> :
                                                            "NA"
                                                }
                                            </Link>
                                        </div>
                                        <div className="site-footer__social" style={{ marginLeft: "9px" }}>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.Twitter }} />
                                            <div dangerouslySetInnerHTML={{ __html: this.state.Facebook }} />
                                            <div dangerouslySetInnerHTML={{ __html: this.state.LinkedIn }} />
                                            <div dangerouslySetInnerHTML={{ __html: this.state.Instagram }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                    <div className="footer-widget__column footer-widget__links clearfix">
                                        <h3 className="footer-widget__title">Contact Us</h3>
                                        <div className="navbar-nav mobile-nav__contact list-unstyled">
                                            <li>
                                                <i className="fa fa-envelope"></i>
                                                <a href={onclick = "return: false"} className="text-white">
                                                    {
                                                        this.state.LoadingFlag ?
                                                            <img src={LoadingGif} alt="" width={130} /> :
                                                            this.state.MobileNo !== "" ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.MobileNo }} /> : "NA"
                                                    }
                                                </a>
                                            </li>
                                            <li>
                                                <i className="fa fa-phone-alt" ></i>
                                                <a href={onclick = "return: false"} className="text-white">
                                                    {
                                                        this.state.LoadingFlag ?
                                                            <img src={LoadingGif} alt="" width={130} /> :
                                                            this.state.MailId !== "" ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.MailId }} /> : "NA"
                                                    }
                                                </a>
                                            </li>
                                            <li>
                                                <i className="fa fa-phone-alt"></i>
                                                <a href={onclick = "return: false"} className="text-white">
                                                    {
                                                        this.state.LoadingFlag ?
                                                            <img src={LoadingGif} alt="" width={130} /> :
                                                            this.state.Address !== "" ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.Address }} /> : "NA"
                                                    }
                                                </a>
                                            </li>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                    <div className="footer-widget__column footer-widget__links clearfix">
                                        <h3 className="footer-widget__title">Quick Links</h3>
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                <ul className="footer-widget__links-list list-unstyled clearfix">
                                                    <li><Link to="/">Home</Link></li>
                                                    <li><Link to="/about">About</Link></li>
                                                    <li><Link to="/event/list">Event</Link></li>
                                                    <li><Link to="/local-organization/list">Local Organization</Link></li>
                                                </ul>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                <ul className="footer-widget__links-list list-unstyled clearfix">
                                                    <li><Link to="/team/lom-governing-board">LOM Governing Board</Link></li>
                                                    <li><Link to="/team/zone-governing-board">Zone Governing Board</Link></li>
                                                    <li><Link to="/gallery">Gallery</Link></li>
                                                    <li><Link to="/contact">Contact</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*
                                <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                                    <div className="footer-widget__column footer-widget__articles clearfix">
                                        <h3 className="footer-widget__title">Articles</h3>
                                        <ul className="footer-widget__articles-list list-unstyled clearfix">
                                            <li>
                                                <div className="footer-widget__articles-img">
                                                    <img src="assets/images/resources/footer-widget-articles-img-1.jpg" alt="" />
                                                    <a href="blog-details.html"><span className="fa fa-link"></span></a>
                                                </div>
                                                <div className="footer-widget__articles-content">
                                                    <p className="footer-widget__articles-date">26 Jan, 2022</p>
                                                    <h5 className="footer-widget__articles-sub-title"><a href="blog-details.html">6
                                                        Cleaning Hacks
                                                        that will Blow your Mind!</a></h5>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-widget__articles-img">
                                                    <img src="assets/images/resources/footer-widget-articles-img-2.jpg" alt="" />
                                                    <a href="blog-details.html"><span className="fa fa-link"></span></a>
                                                </div>
                                                <div className="footer-widget__articles-content">
                                                    <p className="footer-widget__articles-date">26 Jan, 2022</p>
                                                    <h5 className="footer-widget__articles-sub-title"><a href="blog-details.html">6
                                                        Cleaning Hacks
                                                        that will Blow your Mind!</a></h5>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                                    <div className="footer-widget__column footer-widget__newsletter">
                                        <h3 className="footer-widget__title">Newsletter</h3>
                                        <p className="footer-widget__newsletter-text">Subscribe our newsletter to get <br /> our
                                            latest update & news</p>
                                        <form className="footer-widget__newsletter-form">
                                            <div className="footer-widget__newsletter-input-box">
                                                <input type="email" placeholder="Email address" name="email" />
                                                <button type="submit" className="footer-widget__newsletter-btn"><i
                                                    className="far fa-paper-plane"></i></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                */}
                            </div>
                        </div>
                    </div>
                    <div className="site-footer__bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="site-footer__bottom-inner">
                                        <p className="site-footer__bottom-text"> © {CurrentYear} Powered by EZCLUB. &nbsp; Made with <img src={blockTitleHeart} style={{ width: 15 }} alt="" /> <a href="https://vaagai.org.in" target="_blank" rel="noopener noreferrer">Vaagai Tecknowledge</a> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <!--Site Footer End--> */}
            </>
        )
    }
}