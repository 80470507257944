import React, { Component } from "react"
import Footer from "./Footer"
import Header from "./Header"
// import PreLoader from "./home/PreLoader"
import ScrollTop from "./ScrollTop"
// import SearchPopup from "./SearchPopup"

export default class Layout extends Component {
    render() {
        return (
            <>
                {/* <div className="custom-cursor__cursor"></div>
                <div className="custom-cursor__cursor-two"></div> */}
                {/* <PreLoader /> */}
                <div className="page-wrapper">
                    <Header />
                    <main>{this.props.children}</main>
                    <Footer />
                </div>
                {/* <SearchPopup /> */}
                <ScrollTop />
            </>
        )
    }
}