import React, { Component } from "react";
import { Helmet } from "react-helmet";
import EventListPage from "../../components/event/EventListPage";
// import InformationSection from "../../components/InformationSection";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import LoadingGif from "../../loading/loading2.gif"
import axios from "axios"
import LoadingOverlay from 'react-loading-overlay';

var Api_url = process.env.REACT_APP_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class EventList extends Component {
    constructor() {
        super();
        this.state = {
            LoadingFlag: false,
            TotalRecords: 0,
            PageNo: 1,
            Records: [],
            hasMore: true,
        };
    }
    async getAllRecords() {
        console.log("getAllRecords called ");

        var PageNo = this.state.PageNo;
        console.log("PageNo=" + PageNo);

        var url = Api_url + `/api/app/events/lists?page=` + PageNo;
        console.log("url=" + url);
        var postData = {
            "UserInterface": 1,
            "FromDate": "2022-07-01",
            "ToDate": "2022-12-27"
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag: false });

                var flag = res.data.flag;
                var records = res.data.records;
                var TotalPage = records.TotalPage;
                console.log({ records });

                if (flag) {
                    console.log("TotalPage=" + TotalPage + "; PageNo=" + this.state.PageNo + "; hasMore=" + this.state.hasMore);
                    if (TotalPage > this.state.PageNo) {
                        this.setState({ PageNo: this.state.PageNo + 1 });
                    } else {
                        this.setState({ hasMore: false });
                    }

                    var receive_data = records.TableData;
                    var current_data = this.state.Records;
                    var result = current_data.concat(receive_data);
                    var n1 = result.length;
                    console.log("n1=" + n1);

                    this.setState({ Records: result, TotalRecords: n1 });
                } else {
                    this.setState({ hasMore: false });
                }
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called");
        window.scrollTo(0, 0);
        this.getAllRecords();
    }
    render() {
        return (
            <>
                <Helmet>
                    <title> Event List </title>
                    {/* <meta name='description' content='Home' /> */}
                </Helmet>
                <LoadingOverlay
                    spinner
                    active={this.state.LoadingFlag}
                >
                    <Layout>

                        <PageHeader pageTitle="Event" pageText="Event List" />
                        {this.state.LoadingFlag ?
                            <div className="row" >
                                <img src={LoadingGif} alt="Loading" height="250px" />
                            </div> :
                            (this.state.TotalRecords) > 0 ?
                                <EventListPage data={this.state.Records} /> : <p className="text-center">NA</p>
                        }
                        {/* <InformationSection /> */}

                    </Layout>
                </LoadingOverlay>
            </>
        )
    }
}