import React, { Component } from "react";
import axios from "axios";

var Api_url = process.env.REACT_APP_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            Records: {},
            LoadingFlag: false,
            RecordsFlag: false
        };
    }
    async getDetails(id) {
        console.log("getDetails called ");

        // var id = this.state.id;
        console.log("id=" + id);

        if (id > 0) {
            var url = Api_url + `/api/app/members/search`;
            console.log("url=" + url);
            var postData = {
                "UserInterface": 3,
                "MemberDetailsId": id,
            };
            this.setState({ LoadingFlag: true });
            console.log({ postData });
            await axios
                .post(url, postData, axiosConfig)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    console.log("res=" + JSON.stringify(res));

                    this.setState({ LoadingFlag: false });

                    var flag = res.data.flag;
                    var records = res.data.records;
                    console.log({ flag, records });

                    this.setState({ RecordsFlag: flag, Records: records });
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                });
        }
    };

    componentDidMount() {
        console.log("ProfilePageContent componentDidMount called ");
        window.scrollTo(0, 0);
        let currentLocation = this.props.data;
        currentLocation = currentLocation.toString();
        console.log({ currentLocation });
        let searchParams = currentLocation.split('id=');
        var id = searchParams[1] ? parseInt(searchParams[1]) : '';
        console.log({ searchParams });
        console.log({ id });
        this.getDetails(id);
    }

    render() {
        return (
            <>
                {/* <!--Team Details Start--> */}
                <section className="team-details">
                    <div className="container">
                        <div className="team-details__top">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="team-details__top-left">
                                        <div className="team-details__top-img">
                                            <img src={this.state.Records.MemberImage} alt="" style={{height:"400px",width:"350px" }} />
                                            {/* <div className="team-details__big-text">{this.state.Records.Fullname}</div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="team-details__top-right">
                                        <div className="team-details__top-content">
                                            <h3 className="team-details__top-name">{this.state.Records.Fullname}</h3>
                                            <p className="team-details__top-title">{this.state.Records.MemberDesignation}</p>
                                            <div className="team-details__social">
                                                <a href={onclick = "return: false"}><i className="fab fa-twitter"></i></a>
                                                <a href={onclick = "return: false"}><i className="fab fa-facebook"></i></a>
                                                <a href={onclick = "return: false"}><i className="fab fa-pinterest-p"></i></a>
                                                <a href={onclick = "return: false"}><i className="fab fa-instagram"></i></a>
                                            </div>
                                            {/* <p className="team-details__top-text-1"></p> */}
                                            <p className="team-details__top-text-2">MobileNo :- {this.state.Records.MobileNo} <br /> EmailId :- {this.state.Records.EmailId}</p>
                                            <p className="team-details__top-text-3">If you are going to use a passage of Lorem
                                                Ipsum, you need to be sure there isn't anything embarrassing hidden in the
                                                middle of text.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="team-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="team-details__bottom-left">
                                        <h4 className="team-details__bottom-left-title">Personal Experience</h4>
                                        <p className="team-details__bottom-left-text">If you are going to use a passage of Lorem
                                            Ipsum, you need to be sure there isn't anything embarrassing hidden.</p>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="team-details__bottom-right">
                                        <div className="team-details__progress">
                                            <div className="team-details__progress-single">
                                                <h4 className="team-details__progress-title">Cleaning</h4>
                                                <div className="bar">
                                                    <div className="bar-inner count-bar" data-percent="90%">
                                                        <div className="count-text">90%</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="team-details__progress-single">
                                                <h4 className="team-details__progress-title">Washing</h4>
                                                <div className="bar">
                                                    <div className="bar-inner count-bar" data-percent="46%">
                                                        <div className="count-text">46%</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="team-details__progress-single">
                                                <h4 className="team-details__progress-title">Drying</h4>
                                                <div className="bar marb-0">
                                                    <div className="bar-inner count-bar" data-percent="60%">
                                                        <div className="count-text">60%</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>
                {/* <!--Team Details End--> */}
            </>
        )
    }
}