import React, { Component } from "react";
import { Helmet } from "react-helmet";
// import BrandSection from "../components/about/BrandSection";
// import CounterSection from "../components/about/CounterSection";
// import CTASection from "../components/about/CTASection";
import GetToKnow from "../components/about/GetToKnow";
// import InformationSection from "../components/InformationSection";
// import TeamSection from "../components/about/TeamSection";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";

export default class About extends Component {
    componentDidMount() {
        console.log("componentDidMount called");
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <Helmet>
                    <title> About </title>
                    {/* <meta name='description' content='Home' /> */}
                </Helmet>
                <Layout>
                    <PageHeader pageTitle="About" pageText="About us" />
                    <GetToKnow />
                    {/* <CounterSection /> */}
                    {/* <CTASection />
                    <TeamSection /> */}
                    {/* <BrandSection /> */}
                    {/* <InformationSection/> */}
                </Layout>
            </>
        )
    }
}