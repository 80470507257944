import React, { Component } from "react";
import { Link } from "react-router-dom";
// import ListContent from "./ListContent";

export default class EventListPage extends Component {
    render() {
        return (
            <>
                {/* <!--Blog Page Start--> */}
                <section className="blog-page">
                    <div className="container">
                        <div className="row">
                            {this.props.data.map((event, index) => (
                                <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" key={index}>
                                    <div className="blog-one__single">
                                        <div className="blog-one__img">
                                            <img src={event.EventImagePath} alt="" />
                                            {/* <a href={onclick = "return: false"}>
                                                <span className="blog-one__plus"></span>
                                            </a> */}
                                            {/* <div className="blog-one__date">
                                                <p>25 <br /> <span>Mar</span></p>
                                            </div> */}
                                        </div>
                                        <div className="blog-one__content">
                                            <ul className="list-unstyled blog-one__meta">
                                                <li><a href={onclick = "return: false"}><i className="far fa-user-circle"></i> {event.OrganizationTypeName} </a>
                                                </li>
                                                {/* <li><a href="blog-details.html"><i className="far fa-comments"></i> 02 Comments</a>
                                            </li> */}
                                            </ul>
                                            <h3 className="blog-one__title"><Link to={"/event/detail?id=" + event.EventId}>{event.EventName}</Link></h3>
                                            <div className="blog-one__btn-box">
                                                <Link to={"/event/detail?id=" + event.EventId}>Read More <i className="fa fa-angle-right"></i></Link>
                                            </div>
                                            <div className="blog-one__tag">
                                                <p>{event.DateFromTxt}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    {/* <ListContent /> */}
                </section>
                {/* <!--Blog Page End--> */}
            </>
        )
    }
}