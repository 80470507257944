import React, { Component } from "react";
import Layout from "../components/Layout";
import HomeSlider from "../components/home/HomeSlider";
import FeatureSection from "../components/home/FeatureSection"
import WelcomeSection from "../components/home/WelcomeSection"
import ServiceSection from "../components/home/ServiceSection"
// import TeamSection from "../components/home/TeamSection"
// import TestimonialSection from "../components/home/TestimonialSection"
// import GallerySlider from "../components/home/GallerySlider";
// import ContactSection from "../components/home/ContactSection"
// import BlogSection from "../components/home/BlogSection"
import GoogleMapSection from "../components/home/GoogleMapSection"
import { Helmet } from "react-helmet";
// import InformationSection from "../components/home/InformationSection";

import axios from "axios";

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
}

export default class Home extends Component {
    constructor() {
        super();
        this.state = {
            LoadingFlag: false,
            HomeSliders: [],
            PresidentImage: '',
            PresidentMessage: '',
            PresidentLogo: '',
            Creed: '',
            Vision: '',
            Mission: '',
            FoundersPerspective: '',
            MembersOath: '',
            OfficersOath: '',
        };
    }

    async pageDetails() {
        console.log("pageDetails called ");
        var url = Api_url + `/api/app/app-page/show`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            PageName: 'home',
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag: false });

                var flag = res.data.flag;

                // home_slider
                var home_slideshow = res.data.records['home_slideshow'].SettingsValues;

                // president_full_image
                var temp_records1 = res.data.records['president_full_image'].SettingsValues;
                var president_full_image = temp_records1['president_full_image'].FilePath;

                // president_message
                var temp_records2 = res.data.records['president_message'].SettingsValues;
                var president_message = temp_records2['president_message'].SettingsValuesContent;

                // President Logo
                var temp_records3 = res.data.records['president_logo'].SettingsValues;
                var president_logo = temp_records3['president_logo'].FilePath;

                // blocks
                var temp_records5 = res.data.records['blocks'].SettingsValues;
                var creed = temp_records5['creed'].SettingsValuesContent;
                var vision = temp_records5['vision'].SettingsValuesContent;
                var mission = temp_records5['mission'].SettingsValuesContent;
                var founders_perspective = temp_records5['founders_perspective'].SettingsValuesContent;
                var members_oath = temp_records5['members_oath'].SettingsValuesContent;
                var officers_oath = temp_records5['officers_oath'].SettingsValuesContent;

                console.log({ flag });
                console.log({ home_slideshow });
                console.log({ president_full_image });
                console.log({ president_message });
                console.log({ president_logo });
                console.log({ creed });
                console.log({ vision });
                console.log({ mission });
                console.log({ founders_perspective });
                console.log({ members_oath });
                console.log({ officers_oath });

                this.setState({
                    HomeSliders: home_slideshow,
                    PresidentImage: president_full_image,
                    PresidentMessage: president_message,
                    PresidentLogo: president_logo,
                    Creed: creed,
                    Vision: vision,
                    Mission: mission,
                    FoundersPerspective: founders_perspective,
                    MembersOath: members_oath,
                    OfficersOath: officers_oath,
                });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };
    componentDidMount() {
        console.log("componentDidMount called");
        window.scrollTo(0, 0);
        this.pageDetails();
    }
    render() {
        return (
            <>
                <Helmet>
                    <title> Home </title>
                    {/* <meta name='description' content='Home -' /> */}
                </Helmet>
                <Layout>
                    <HomeSlider />
                    <FeatureSection Creed={this.state.Creed} Vision={this.state.Vision} Mission={this.state.Mission} LoadingFlag={this.state.LoadingFlag} />
                    <WelcomeSection PresidentImage={this.state.PresidentImage} PresidentLogo={this.state.PresidentLogo} PresidentMessage={this.state.PresidentMessage} LoadingFlag={this.state.LoadingFlag} />
                    <ServiceSection FoundersPerspective={this.state.FoundersPerspective} MembersOath={this.state.MembersOath} OfficersOath={this.state.OfficersOath} LoadingFlag={this.state.LoadingFlag} />
                    {/* <TeamSection />
                    <TestimonialSection /> */}
                    {/* <GallerySlider /> */}
                    {/* <ContactSection /> */}
                    {/* <BlogSection /> */}
                    <GoogleMapSection />
                    {/* <InformationSection /> */}
                </Layout>
            </>
        );
    }
}