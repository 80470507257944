import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import ProfilePage from "../../components/team/ProfilePage";
// import MessageOneSection from "../../components/team/MessageOneSection";
// import InformationSection from "../../components/InformationSection";

export default class Profile extends Component {
    componentDidMount() {
        console.log("Profile componentDidMount called ");
        let currentLocation = window.location.toString();
        console.log({ currentLocation });
    }
    render() {
        return (
            <>
                <Helmet>
                    <title> Profile </title>
                    {/* <meta name='description' content='Home' /> */}
                </Helmet>
                <Layout>
                    <PageHeader pageTitle="Team" pageText="Profile" />
                    <ProfilePage data={window.location} />
                    {/* <MessageOneSection /> */}
                    {/* <InformationSection /> */}
                </Layout>
            </>
        )
    }
}