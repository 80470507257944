import React, { Component } from "react";
import axios from 'axios';
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// import { Swiper } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import LoadingGif from "../../loading/loading.gif";

const Image1 = process.env.PUBLIC_URL + '/assets/images/shapes/main-slider-bubble-bg.png';
const Image2 = process.env.PUBLIC_URL + '/assets/images/backgrounds/main-slider-1-1.jpg';
console.log("Image2 = ", Image2);

SwiperCore.use([Navigation, Scrollbar, A11y, Autoplay, Pagination, EffectFade]);

var Api_url = process.env.REACT_APP_API_URL;


var loading1 = Api_url + `/assets/website/images/loading_3.gif`;
console.log("loading1=" + loading1);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};

const slides = [];

export default class HomeSlider extends Component {
    constructor() {
        super();
        this.state = {
            Slides: slides,
            LoadingFlag: false,
            Records: [],
            TotalSlides: 0,
        };
    }

    async getAllRecords() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            SettingsName: "home_slideshow",
        };
        console.log({ postData });
        this.setState({ LoadingFlag: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = Object.keys(result).length;
                console.log({ n1 });
                console.log({ result });

                this.setState({ LoadingFlag: false, Slides: result, TotalSlides: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called");
        window.scrollTo(0, 0);
        this.getAllRecords();
    }

    render() {
        return (
            <>

                {/* <!--Main Slider Start--> */}
                <section className="main-slider clearfix">
                    <div className="swiper-container thm-swiper__slider">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="row">
                                    <Swiper
                                        navigation
                                        pagination
                                        spaceBetween={50}
                                        slidesPerView={1}
                                        autoplay
                                        loop
                                        onSlideChange={() => console.log("slide change")}
                                        onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        <div className="col-12">
                                            <div className="image-layer">
                                                {this.state.LoadingFlag
                                                    ?
                                                    <div>
                                                        < img src={LoadingGif} alt="Loading" width="50%" style={{ alignItem: "center" }} />
                                                    </div>
                                                    : this.state.TotalSlides > 0
                                                        ? this.state.Slides.map((item, index) => (
                                                            <SwiperSlide key={index}>
                                                                <img width="100%" height={760} src={item.FilePath} alt="" />
                                                                {item.SettingsValuesContent ?
                                                                    <p style={{
                                                                        color: "black",
                                                                        position: "absolute",
                                                                        top: "70px",
                                                                        width: "100%",
                                                                        textAlign: "center",
                                                                    }}>
                                                                        {item.SettingsValuesContent}
                                                                    </p>
                                                                    : ''}
                                                            </SwiperSlide>
                                                        ))
                                                        :
                                                        <div>
                                                            <img width="100%" src={Image2} alt="" />
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </Swiper>
                                </div>
                                <div className="main-slider-bubble">
                                    <div className="main-slider-bubble-bg" style={{ background: `url(${Image1})` }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                {/* <!--Main Slider End--> */}
            </>
        );
    }
}