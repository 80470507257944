import React, { Component } from "react";
import axios from "axios";
import PresidentDetails from "./PresidentDetails";
import BoardMembersDetail from "./BoardMembersDetail";
import MemberDetails from "./MemberDetails";
import LoadingContent from "../LoadingContent";

var Api_url = process.env.REACT_APP_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class OrganizationDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            Records: [],
            LoadingFlag: false,
            TotalRecords: 0,

            PresidentDetails: [],
            BoardMembersDetail: [],
            MemberDetails: [],

            show1: true,
            show2: false,
            BackgroundColor1: "#1239ac",
            TextColor1: "white",
            BackgroundColor2: "whitesmoke",
            TextColor2: "black",
        };
    }

    async getDetails(id) {
        console.log("getRecords called ");
        console.log({ id });

        if (id > 0) {

            var url = Api_url + `/api/app/yearwise-team/get_lom_members_by_lom_id`;
            console.log("url=" + url);
            var postData = {
                "UserInterface": 1,
                "Year": 4,
                "LomId": id,
            };
            console.log("postData=" + JSON.stringify(postData));
            this.setState({ LoadingFlag: true });
            await axios
                .post(url, postData, axiosConfig)
                .then(res => {
                    console.log("RESPONSE RECEIVED: ", res);
                    console.log("res=" + JSON.stringify(res));

                    var result = res.data.records.TableData;
                    var n1 = result.length;
                    console.log("n1=" + n1);
                    console.log("result=" + JSON.stringify(result));

                    var result2 = result.PresidentDetails;
                    console.log("result2=" + JSON.stringify(result2));

                    var result3 = result.BoardMembersDetail;
                    var n3 = result3.length;
                    console.log("n3=" + n3);
                    console.log("result3=" + JSON.stringify(result3));

                    var result4 = result.MemberDetails;
                    var n4 = result4.length;
                    console.log("n4=" + n4);
                    console.log("result4=" + JSON.stringify(result4));

                    this.setState({ LoadingFlag: false, TotalRecords: n1 });
                    this.setState({ PresidentDetails: result2, BoardMembersDetail: result3, MemberDetails: result4 });
                })
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
        }
    }
    showBoardMembers() {
        console.log("showBoardMembers called");
        this.setState({ show1: true, show2: false });

        this.setState({ BackgroundColor1: "#1239ac", TextColor1: "white" });
        this.setState({ BackgroundColor2: "whitesmoke", TextColor2: "black" });
    }

    showMembers() {
        console.log("showMembers called");
        this.setState({ show2: true, show1: false });
        this.setState({ BackgroundColor1: "whitesmoke", TextColor1: "black" });
        this.setState({ BackgroundColor2: "#1239ac", TextColor2: "white" });
    }

    componentDidMount() {
        console.log("OrganizationDetail componentDidMount called ");
        window.scrollTo(0, 0);
        let currentLocation = this.props.data;
        currentLocation = currentLocation.toString();
        console.log({ currentLocation });
        let searchParams = currentLocation.split('id=');
        var id = searchParams[1] ? parseInt(searchParams[1]) : '';
        console.log({ searchParams });
        console.log({ id });
        this.getDetails(id);
    }

    render() {
        return (
            <>
                <section className="blog-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                {
                                    this.state.LoadingFlag ? <LoadingContent /> :
                                        <div>
                                            <PresidentDetails Records={this.state.PresidentDetails} />
                                            <div align="center">
                                                <p onClick={this.showBoardMembers.bind(this)} className="local-org-btn" style={{ backgroundColor: this.state.BackgroundColor1, color: this.state.TextColor1 }}>Board Members</p> &nbsp; &nbsp;
                                                <p onClick={this.showMembers.bind(this)} className="local-org-btn" style={{ backgroundColor: this.state.BackgroundColor2, color: this.state.TextColor2 }}> Members</p>
                                                <br></br>  <br></br> <br></br> <br></br> <br></br>
                                                {
                                                    this.state.show1 ?
                                                        this.state.LoadingFlag ?
                                                            <LoadingContent /> :
                                                            <BoardMembersDetail Records={this.state.BoardMembersDetail} />
                                                        : ""
                                                }
                                                {
                                                    this.state.show2 ?
                                                        this.state.LoadingFlag ?
                                                            <LoadingContent /> :
                                                            <MemberDetails Records={this.state.MemberDetails} />
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}