import React, { Component } from "react";
import { Helmet } from "react-helmet";
import GalleryPage from "../components/gallery/GalleryPage";
// import InformationSection from "../components/InformationSection";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";

export default class Gallery extends Component {
    componentDidMount() {
        console.log("componentDidMount called");
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <Helmet>
                    <title> Gallery </title>
                    {/* <meta name='description' content='Home -' /> */}
                </Helmet>
                <Layout>
                    <PageHeader pageTitle="Gallery" pageText="Gallery"/>
                    <GalleryPage />
                    {/* <InformationSection /> */}
                </Layout>
            </>
        )
    }
}