import React, { Component } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import LoadingGif from "../loading/loading2.gif";
import RoundGif from "../loading/round.gif"

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
}

export default class Header extends Component {
    constructor() {
        super();
        // this.state = {
        //     Records1: [],
        //     Total1: 0,
        //     LoadingFlag1: false,

        //     PresidentLogo: {},
        //     Total2: 0,
        //     LoadingFlag2: false,

        //     Records3: [],
        //     Total3: 0,
        //     LoadingFlag3: false,

        //     Records4: [],
        //     Total4: 0,
        //     LoadingFlag4: false,

        //     Records5: [],
        //     Total5: 0,
        //     LoadingFlag5: false,

        //     Records6: [],
        //     Total6: 0,
        //     LoadingFlag6: false,

        //     Records7: [],
        //     Total7: 0,
        //     LoadingFlag7: false,

        //     Records8: [],
        //     Total8: 0,
        //     LoadingFlag8: false,

        //     Records: [],
        //     LoadingFlag: false,
        // };

        this.state = {
            LoadingFlag: false,
            Blocks: "",
            Logo: "",

            MobileNo: "",
            MailId: "",
            Address: "",
            Mission: "",
            Vision: "",
            Creed: "",
            Facebook: "",
            Twitter: "",
            Instagram: "",
            LinkedIn: "",
            MembersOath: "",
            OfficersOath: "",
            FoundersPerspective: "",
            CompanyName: "",
            PresidentLogo: "",
        };
    }

    async getAllRecords1() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'mail_id',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag1: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag1: false, Records1: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords2() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            SettingsName: "president_logo",
        };
        this.setState({ LoadingFlag2: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = Object.keys(result).length;
                console.log({ n1 });
                console.log({ result });

                this.setState({ LoadingFlag2: false, PresidentLogo: result, Total2: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords3() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'facebook',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag3: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag3: false, Records3: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords4() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'twitter',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag4: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag4: false, Records4: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords5() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'instagram',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag5: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag5: false, Records5: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords6() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'linkedin',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag6: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag6: false, Records6: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords7() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'company_name',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag7: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag7: false, Records7: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords8() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'address',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag8: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag: false });

                var flag = res.data.flag;
                var records = res.data.records;
                console.log({ records });

                this.setState({ LoadingFlag8: false, RecordsFlag8: flag, Records8: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getDetails() {
        console.log("getDetails called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'mobile_no',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var flag = res.data.flag;
                var records = res.data.records;
                console.log({ flag, records });

                this.setState({ LoadingFlag: false, RecordsFlag: flag, Records: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };

    async pageDetails() {
        console.log("pageDetails called ");
        var url = Api_url + `/api/app/app-page/show`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            PageName: 'header',
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var flag = res.data.flag;
                var blocks = res.data.records['blocks'];
                var president_logo = res.data.records['president_logo'];

                console.log({ flag, blocks });
                console.log({ president_logo });

                this.setState({ LoadingFlag: false, Blocks: blocks, PresidentLogo: president_logo });

                // var settingsvalues = records.SettingsValues[0].SettingsValuesContent;
                // console.log(settingsvalues);
                var mobile_no = blocks.SettingsValues['mobile_no'].SettingsValuesContent;
                var mail_id = blocks.SettingsValues['mail_id'].SettingsValuesContent;
                var address = blocks.SettingsValues['address'].SettingsValuesContent;
                var mission = blocks.SettingsValues['mission'].SettingsValuesContent;
                var vision = blocks.SettingsValues['vision'].SettingsValuesContent;
                var creed = blocks.SettingsValues['creed'].SettingsValuesContent;
                var facebook = blocks.SettingsValues['facebook'].SettingsValuesContent;
                var twitter = blocks.SettingsValues['twitter'].SettingsValuesContent;
                var instagram = blocks.SettingsValues['instagram'].SettingsValuesContent;
                var linkedin = blocks.SettingsValues['linkedin'].SettingsValuesContent;
                var members_oath = blocks.SettingsValues['members_oath'].SettingsValuesContent;
                var officers_oath = blocks.SettingsValues['officers_oath'].SettingsValuesContent;
                var founders_perspective = blocks.SettingsValues['founders_perspective'].SettingsValuesContent;
                var company_name = blocks.SettingsValues['company_name'].SettingsValuesContent;

                var logo = president_logo.SettingsValues['president_logo'].FilePath;

                this.setState({
                    MobileNo: mobile_no,
                    MailId: mail_id,
                    Address: address,
                    Mission: mission,
                    Vision: vision,
                    Creed: creed,
                    Facebook: facebook,
                    Twitter: twitter,
                    Instagram: instagram,
                    LinkedIn: linkedin,
                    MembersOath: members_oath,
                    OfficersOath: officers_oath,
                    FoundersPerspective: founders_perspective,
                    CompanyName: company_name,
                    Logo: logo,
                });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };

    componentDidMount() {
        console.log("componentDidMount called ");
        // this.getAllRecords1();
        // this.getAllRecords2();
        // this.getAllRecords3();
        // this.getAllRecords4();
        // this.getAllRecords5();
        // this.getAllRecords6();
        // this.getAllRecords7();
        // this.getAllRecords8();
        // this.getDetails();

        this.pageDetails();
    }

    render() {
        return (
            <>
                <header className="main-header clearfix">
                    <div className="main-header__top">
                        <div className="main-header__top-social-box">
                            <div className="container">
                                <div className="main-header__top-social-box-inner">
                                    <p className="main-header__top-social-text">JCI India Zone-18 Welcomes You !</p>
                                    <div className="main-header__top-social">
                                        <div dangerouslySetInnerHTML={{ __html: this.state.Twitter }} />
                                        <div dangerouslySetInnerHTML={{ __html: this.state.Facebook }} style={{ marginLeft: "8px" }} />
                                        <div dangerouslySetInnerHTML={{ __html: this.state.LinkedIn }} style={{ marginLeft: "8px" }} />
                                        <div dangerouslySetInnerHTML={{ __html: this.state.Instagram }} style={{ marginLeft: "8px" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-header__top-details">
                            <div className="container">
                                <div className="main-header__top-details-inner">
                                    <div className="main-header__logo">
                                        <Link to="/">
                                            {
                                                this.state.LoadingFlag ?
                                                    <img src={RoundGif} alt="" style={{ height: "80px", width: "90px", borderRadius: "100%" }} /> :
                                                    this.state.Logo !== "" ?
                                                        <img src={this.state.Logo} style={{ width: "80px", height: "80px", borderRadius: "100%" }} alt="" /> :
                                                        "NA"
                                            }
                                        </Link>
                                    </div>
                                    <ul className="list-unstyled main-header__top-details-list">
                                        <li>
                                            <div className="icon">
                                                <span className="icon-phone-call"></span>
                                            </div>
                                            <div className="text">
                                                <h5>Call Anytime</h5>
                                                <div className="display-text">
                                                    {
                                                        this.state.LoadingFlag ?
                                                            <img src={LoadingGif} alt="" width={130} /> :
                                                            this.state.MobileNo !== "" ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.MobileNo }} /> : "NA"
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <span className="icon-message"></span>
                                            </div>
                                            <div className="text">
                                                <h5>Send mail</h5>
                                                <div className="display-text">
                                                    {
                                                        this.state.LoadingFlag ?
                                                            <img src={LoadingGif} alt="" width={130} /> :
                                                            this.state.MailId !== "" ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.MailId }} /> : "NA"
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <span className="icon-location"></span>
                                            </div>
                                            <div className="text">
                                                <h5>Address</h5>
                                                <div className="display-text">
                                                    {
                                                        this.state.LoadingFlag ?
                                                            <img src={LoadingGif} alt="" width={130} /> :
                                                            this.state.Address !== "" ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.Address }} /> : "NA"
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="login">
                                                <Link to="/" >
                                                    <button className="badge rounded-pill" style={{ border: "none", padding: "12px 18px", fontSize: "16px", backgroundColor: "blue", fontFamily: "Lora" }}><i className="fa fa-user"></i> Login</button>
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="login-icon">
                                        <div className="d-flex justify-content-center">
                                            <Link to="/" >
                                                <button className="badge rounded-pill " style={{ border: "none", padding: "12px 40px", fontSize: "18px", backgroundColor: "blue", fontFamily: "Lora" }}><i className="fa fa-user"></i> Login</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="main-menu clearfix">
                        <div className="main-menu__wrapper clearfix">
                            <div className="container">
                                <div className="main-menu__wrapper-inner clearfix">
                                    <div className="main-menu__left">
                                        <div className="main-menu__main-menu-box">
                                            {/* <Link className="mobile-nav__toggler">
                                                <i className="fa fa-bars"></i>
                                            </Link> */}

                                            <button className="navbar-toggler" data-mdb-right="true" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{ padding: "20px" }}>
                                                {/* <span className="navbar-toggler-icon"></span> */}
                                                <i className="fa fa-bars" style={{ color: "white" }}></i>
                                            </button>
                                            <ul className="main-menu__list">
                                                <li>
                                                    <Link to="/" className="text-white">Home</Link>
                                                </li>
                                                <li>
                                                    <Link to="/about" className="text-white">About</Link>
                                                </li>
                                                <li className="dropdown">
                                                    <a href={onclick = "return: false"} className="text-white">Event&nbsp;<i className='fas fa-angle-down'></i></a>
                                                    <ul>
                                                        <li><Link to="/event/list" className="text-dark">Event List</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown">
                                                    <a href={onclick = "return: false"} className="text-white">Team&nbsp;<i className='fas fa-angle-down'></i></a>
                                                    <ul>
                                                        <li><Link to="/team/zone-governing-board" className="text-dark">Zone Governing Board</Link></li>
                                                        <li><Link to="/team/lom-governing-board" className="text-dark">LOM Governing Board</Link></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to="/local-organization/list" className="text-white">Local-Organization</Link>
                                                </li>
                                                <li>
                                                    <Link to="/gallery" className="text-white">Gallery</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact" className="text-white">Contact</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>

                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content">
                        {/* <div className="sticky" style={{ backgroundColor: "orange" }}>This div will stick to the top</div> */}
                        <div className="main-menu__main-menu-box">
                            <a href={onclick = "return: false"} className="mobile-nav__toggler"><i className="fa fa-bars"></i></a>
                            <ul className="main-menu__list d-flex justify-content-center">
                                <li>
                                    <Link to="/" className="text-white">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about" className="text-white">About</Link>
                                </li>
                                <li className="dropdown">
                                    <a href={onclick = "return: false"} className="text-white">Event&nbsp;<i className='fas fa-angle-down'></i></a>
                                    <ul>
                                        <li><Link to="/event/list" className="text-dark">Event List</Link></li>
                                    </ul>
                                </li>
                                <li className="dropdown">
                                    <a href={onclick = "return: false"} className="text-white">Team&nbsp;<i className='fas fa-angle-down'></i></a>
                                    <ul>
                                        <li><Link to="/team/zone-governing-board" className="text-dark">Zone Governing Board</Link></li>
                                        <li><Link to="/team/lom-governing-board" className="text-dark">LOM Governing Board</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/local-organization/list" className="text-white">Local-Organization</Link>
                                </li>
                                <li>
                                    <Link to="/gallery" className="text-white">Gallery</Link>
                                </li>
                                <li>
                                    <Link to="/contact" className="text-white">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Mobile Menu */}
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <hr />
                    <ul className="navbar-nav me-auto mb-8 mb-lg-0" style={{ marginLeft: "20px", fontFamily: "Open Sans" }}>
                        <li className="nav-item">
                            <Link to="/" className="nav-link text-dark">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about" className="nav-link text-dark">About</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle text-dark" href={onclick = "return: false"} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Event</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><Link to="/event/list" className="dropdown-item text-dark">Event List</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle text-dark" href={onclick = "return: false"} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Team</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><Link to="/team/zone-governing-board" className="dropdown-item text-dark" >Zone Governing Board</Link></li>
                                <hr />
                                <li><Link to="/team/lom-governing-board" className="dropdown-item text-dark">LOM Governing Board</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link to="/local-organization/list" className="nav-link text-dark">Local-Organization</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/gallery" className="nav-link text-dark">Gallery</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/contact" className="nav-link text-dark">Contact</Link>
                        </li>
                        {/* member login button */}
                        <hr />
                        <Link to="/" >
                            <button className="badge rounded-pill" style={{ border: "none", padding: "12px 40px", fontSize: "16px", backgroundColor: "blue", fontFamily: "Lora" }}><i className="fa fa-user"></i> Login</button>
                        </Link>
                        <hr />
                        {/* contact details */}
                        <div className="row d-flex align-item-center" style={{ marginBottom: "30px" }}>
                            <div className="col-sm-12 col-md-12 col-xl-12 col-lg-12">
                                <div className="navbar-nav mobile-nav__contact list-unstyled">
                                    <li>
                                        <i className="fa fa-envelope"></i>
                                        <a href={onclick = "return: false"} className="text-dark">
                                            {
                                                this.state.LoadingFlag ?
                                                    <img src={LoadingGif} alt="" width={130} /> :
                                                    this.state.MobileNo !== "" ?
                                                        <div dangerouslySetInnerHTML={{ __html: this.state.MobileNo }} /> : "NA"
                                            }
                                        </a>
                                        <i className="fa fa-phone-alt" style={{ marginLeft: "18px" }} ></i>
                                        <a href={onclick = "return: false"} className="text-dark">
                                            {
                                                this.state.LoadingFlag ?
                                                    <img src={LoadingGif} alt="" width={130} /> :
                                                    this.state.MailId !== "" ?
                                                        <div dangerouslySetInnerHTML={{ __html: this.state.MailId }} /> : "NA"
                                            }
                                        </a>
                                    </li>
                                    <li>
                                        <i className="fa fa-phone-alt"></i>
                                        <a href={onclick = "return: false"} className="text-dark">
                                            {
                                                this.state.LoadingFlag ?
                                                    <img src={LoadingGif} alt="" width={130} /> :
                                                    this.state.Address !== "" ?
                                                        <div dangerouslySetInnerHTML={{ __html: this.state.Address }} /> : "NA"
                                            }
                                        </a>
                                    </li>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-xl-12 col-lg-12" style={{ marginBottom: "20px" }}>
                                <div className="mobile-nav__top">
                                    <div className="mobile-nav__social">
                                        <div dangerouslySetInnerHTML={{ __html: this.state.Twitter }} />
                                        <div dangerouslySetInnerHTML={{ __html: this.state.Facebook }} style={{ marginLeft: "24px" }} />
                                        <div dangerouslySetInnerHTML={{ __html: this.state.Instagram }} style={{ marginLeft: "24px" }} />
                                        <div dangerouslySetInnerHTML={{ __html: this.state.LinkedIn }} style={{ marginLeft: "24px" }} />
                                    </div>
                                    <hr />
                                </div>
                            </div>
                            <div style={{ marginBottom: "20px" }}></div>
                        </div>
                    </ul>
                </div>
            </>
        );
    }
}
