import React, { Component } from "react";
import axios from "axios";
import LoadingGif from "../../loading/loading2.gif";

const Image1 = process.env.PUBLIC_URL + '/assets/images/shapes/services-one-single-top-bubble.jpg';
const Image2 = process.env.PUBLIC_URL + '/assets/images/shapes/services-one-single-overlay-bg-1.png';

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
}

export default class ServiceSectionContent extends Component {
    constructor() {
        super();
        this.state = {
            Records1: [],
            LoadingFlag1: false,
            RecordsFlag1: false,

            Records2: [],
            LoadingFlag2: false,
            RecordsFlag2: false,

            Records3: [],
            LoadingFlag3: false,
            RecordsFlag3: false,
        };
    }
    async getDetails1() {
        console.log("getDetails called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'founders_perspective',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag1: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag: false });

                var flag = res.data.flag;
                var records = res.data.records;
                console.log({ flag, records });

                this.setState({ LoadingFlag1: false, RecordsFlag1: flag, Records1: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };
    async getDetails2() {
        console.log("getDetails called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'members_oath',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag2: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag: false });

                var flag = res.data.flag;
                var records = res.data.records;
                console.log({ flag, records });

                this.setState({ LoadingFlag2: false, RecordsFlag2: flag, Records2: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };
    async getDetails3() {
        console.log("getDetails called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'officers_oath',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag3: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag3: false });

                var flag = res.data.flag;
                var records = res.data.records;
                console.log({ flag, records });

                this.setState({ LoadingFlag3: false, RecordsFlag3: flag, Records3: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };

    // componentDidMount() {
    //     console.log("componentDidMount called ");
    //     this.getDetails1();
    //     this.getDetails2();
    //     this.getDetails3();
    // }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 wow fadeInUp" data-wow-delay="100ms">
                        <div className="services-one__single">
                            <div className="services-one__single-top-bubble" style={{ backgroundImage: `url(${Image1})` }}>
                            </div>
                            <div className="services-one__icon">
                                <span className="icon-plumbing"></span>
                            </div>
                            <div className="services-one__single-inner">
                                <div className="services-one__title-box">
                                    <h3 className="services-one__title">The Founder's Perspective</h3>
                                </div>
                                <div className="services-one__text-box">
                                    <div className="services-one__text">
                                        {
                                            this.props.LoadingFlag ?
                                                <img src={LoadingGif} alt="" width={240} /> :
                                                this.props.FoundersPerspective !== "" ?
                                                    <div dangerouslySetInnerHTML={{ __html: this.props.FoundersPerspective }}></div> : "NA"
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="services-one__overly-box" style={{ backgroundImage: `url(${Image2})` }}>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 wow fadeInUp" data-wow-delay="200ms">
                        <div className="services-one__single">
                            <div className="services-one__single-top-bubble" style={{ backgroundImage: `url(${Image1})` }}>
                            </div>
                            <div className="services-one__icon">
                                <span className="icon-worker"></span>
                            </div>
                            <div className="services-one__single-inner">
                                <div className="services-one__title-box">
                                    <h3 className="services-one__title">Member's Oath</h3>
                                </div>
                                <div className="services-one__text-box">
                                    <div className="services-one__text">
                                        {
                                            this.props.LoadingFlag ?
                                                <img src={LoadingGif} alt="" width={240} /> :
                                                this.props.MembersOath !== "" ?
                                                    <div dangerouslySetInnerHTML={{ __html: this.props.MembersOath }}></div> : "NA"
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="services-one__overly-box" style={{ backgroundImage: `url(${Image2})` }}>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 wow fadeInUp" data-wow-delay="300ms">
                        <div className="services-one__single">
                            <div className="services-one__single-top-bubble" style={{ backgroundImage: `url(${Image1})` }}>
                            </div>
                            <div className="services-one__icon">
                                <span className="icon-laundry"></span>
                            </div>
                            <div className="services-one__single-inner">
                                <div className="services-one__title-box">
                                    <h3 className="services-one__title">Officer's Oath</h3>
                                </div>
                                <div className="services-one__text-box">
                                    <div className="services-one__text">
                                        {
                                            this.props.LoadingFlag ?
                                                <img src={LoadingGif} alt="" width={240} /> :
                                                this.props.OfficersOath !== "" ?
                                                    <div dangerouslySetInnerHTML={{ __html: this.props.OfficersOath }}></div> : "NA"
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="services-one__overly-box" style={{ backgroundImage: `url(${Image2})` }}>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}