import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import OrganizationDetail from "../../components/local_organization/OrganizationDetail";

export default class LocalOrganizationDetail extends Component {
    componentDidMount() {
        console.log("LocalOrganizationDetail componentDidMount called ");
        let currentLocation = window.location.toString();
        console.log({ currentLocation });
    }
    render() {
        return (
            <>
                <Helmet>
                    <title> Local Organization Details </title>
                    {/* <meta name='description' content='Local Organization' /> */}
                </Helmet>
                <Layout>
                    <PageHeader pageTitle="Local Organization Detail" pageText="Local Organization Detail" />
                    <OrganizationDetail data={window.location} />
                    {/* <OrganizationDetail /> */}
                </Layout>
            </>
        )
    }
}