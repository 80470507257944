import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class MemberDetails extends Component {
    render() {
        const member = this.props.Records;
        const n1 = this.props.Records.length;
        return (
            <>
                <div className="row">
                    {
                        n1 > 0 ?
                            member.map((item, index) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow fadeInUp" key={index}>
                                    <div className="local-org-one__single">
                                        <div className="local-org-one__content">
                                            <h3 className="local-org-one__title">
                                                <img src={item.ProfilePic} alt="" height={100} width={100} style={{ borderRadius: "100%" }} />
                                                <br />
                                                Name : <Link to={"/profile?id=" + item.MemberId}>{item.MemberName}</Link>
                                            </h3>
                                            {/* <div className="local-org-one__btn-box">
                                        <p>{item.DesignationName}</p>
                                    </div> */}
                                            <div className="local-org-one__tag">
                                                <p> {item.DesignationName} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : "NA"
                    }
                </div>
            </>
        )
    }
}