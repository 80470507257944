import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Routes, Route } from "react-router-dom";
// import AppPage from './App';
import ErrorPage from './ErrorPage';
import About from './pages/About';
import Contact from './pages/Contact';
import EventList from './pages/event/EventList';
import Gallery from './pages/Gallery';
import Home from './pages/Home';
import EventDetail from './pages/event/EventDetail';
import ZoneGoverningBoard from './pages/team/ZoneGoverningBoard';
import LOMGoverningBoard from './pages/team/LOMGoverningBoard';
import LocalOrganizationList from './pages/local_organization/LocalOrganizationList';
import LocalOrganizationDetail from './pages/local_organization/LocalOrganizationDetail';
import Profile from './pages/team/Profile';
import PaymentSuccess from './pages/PaymentSuccess';

export default function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route path="about" element={<About />}></Route>
          <Route path="gallery" element={<Gallery />}></Route>
          <Route path="local-organization/list" element={<LocalOrganizationList />}></Route>
          <Route path="local-organization/detail" element={<LocalOrganizationDetail />}></Route>
          <Route path="team/zone-governing-board" element={<ZoneGoverningBoard />}></Route>
          <Route path="team/lom-governing-board" element={<LOMGoverningBoard />}></Route>
          <Route path="profile" element={<Profile />}></Route>
          <Route path="event/list" element={<EventList />}></Route>
          <Route path="event/detail" element={<EventDetail />}></Route>
          <Route path="contact" element={<Contact />}></Route>
          <Route path="payment-success" element={<PaymentSuccess />}></Route>
          {/* <Route path="app" element={<AppPage/>}></Route> */}
          <Route path="*" element={<ErrorPage />}></Route>
        </Routes>
      </HashRouter>
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
