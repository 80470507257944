import React, { Component } from "react";

export default class GoogleMap extends Component {
    render() {
        return (
            <>
                {/* <!--Google Map Start--> */}
                <section className="google-map google-map--two">
                    {/* <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd"
                        className="google-map__one" title="googlemap" allowFullScreen></iframe> */}

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3928.208550023854!2d78.77833381479486!3d10.081991092795022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00676fe95e7bef%3A0x49aac7b30205f241!2s1st%20St%2C%20Karaikudi%2C%20Tamil%20Nadu%20630002!5e0!3m2!1sen!2sin!4v1675583807298!5m2!1sen!2sin" allowfullscreen="" className="google-map__one" title="googlemap"></iframe>

                </section>
                {/* <!--Google Map End--> */}
            </>
        )
    }
}