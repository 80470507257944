import React, { Component } from "react";
import axios from "axios";
import LoadingGif from "../../loading/loading2.gif";

// const Image1 = process.env.PUBLIC_URL + '/assets/images/backgrounds/feature-one-single-bg.jpg';
const Image1 = '';

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
}

export default class FeatureSection extends Component {
    constructor() {
        super();
        this.state = {
            Records1: [],
            LoadingFlag1: false,
            RecordsFlag1: false,

            Records2: [],
            LoadingFlag2: false,
            RecordsFlag2: false,

            Records3: [],
            LoadingFlag3: false,
            RecordsFlag3: false
        };
    }
    async getDetails1() {
        console.log("getDetails called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'mission',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag1: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag1: false });

                var flag = res.data.flag;
                var records = res.data.records;
                console.log({ flag, records });

                this.setState({ RecordsFlag1: flag, Records1: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };

    async getDetails2() {
        console.log("getDetails called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'vision',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag2: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag2: false });

                var flag = res.data.flag;
                var records = res.data.records;
                console.log({ flag, records });

                this.setState({ RecordsFlag2: flag, Records2: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };

    async getDetails3() {
        console.log("getDetails called ");
        var url = Api_url + `/api/app-block-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2,
            BlockTitle: 'creed',
            AppName: "website",
            SettingsName: "blocks",
        };
        this.setState({ LoadingFlag3: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag3: false });

                var flag = res.data.flag;
                var records = res.data.records;
                console.log({ flag, records });

                this.setState({ RecordsFlag3: flag, Records3: records });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };

    componentDidMount() {
        console.log("componentDidMount called ");
        // this.getDetails1();
        // this.getDetails2();
        // this.getDetails3();
    }

    render() {
        return (
            <>
                {/* <!--Feature One Start--> */}
                <section className="feature-one">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay="100ms">
                                <div className="feature-one__single">
                                    <div className="feature-one-single-bg" style={{ backgroundImage: `url(${Image1})` }}>
                                    </div>
                                    <div className="feature-one__icon">
                                        <img src="assets/images/icon/feature-one-icon-1.png" alt="" />
                                        <div className="feature-one__icon-shape">
                                            <img src="assets/images/shapes/feature-one-icon-shape.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="feature-one__title-box">
                                        <div className="feature-one__title-border"></div>
                                        <h3 className="feature-one__title"><a href={onclick = "return: false"}>Our Mission</a></h3>
                                    </div>
                                    <div className="feature-one__text">
                                        {this.props.LoadingFlag ? <img src={LoadingGif} alt="" height={150} width={240} /> :
                                            this.props.Mission !== "" ? <div dangerouslySetInnerHTML={{ __html: this.props.Mission }} /> : "NA"
                                        }
                                    </div>
                                    {/* <div className="feature-one__btn-box">
                                        <a href="about.html" className="feature-one__btn">View more</a>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay="200ms">
                                <div className="feature-one__single">
                                    <div className="feature-one-single-bg" style={{ backgroundImage: `url(${Image1})` }}>
                                    </div>
                                    <div className="feature-one__icon">
                                        <img src="assets/images/icon/feature-one-icon-2.png" alt="" />
                                        <div className="feature-one__icon-shape">
                                            <img src="assets/images/shapes/feature-one-icon-shape.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="feature-one__title-box">
                                        <div className="feature-one__title-border"></div>
                                        <h3 className="feature-one__title"><a href={onclick = "return: false"}>Our Vision</a></h3>
                                    </div>
                                    <div className="feature-one__text">
                                        {this.props.LoadingFlag ? <img src={LoadingGif} alt="" height={150} width={240} /> :
                                            this.props.Vision ? <div dangerouslySetInnerHTML={{ __html: this.props.Vision }}></div> : "NA"}
                                    </div>
                                    {/* <div className="feature-one__btn-box">
                                        <a href="about.html" className="feature-one__btn">View more</a>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 wow fadeInUp" data-wow-delay="300ms">
                                <div className="feature-one__single">
                                    <div className="feature-one-single-bg" style={{ backgroundImage: `url(${Image1})` }}>
                                        {/* style="background-image: url(assets/images/backgrounds/feature-one-single-bg.jpg);"> */}
                                    </div>
                                    <div className="feature-one__icon">
                                        <img src="assets/images/icon/feature-one-icon-3.png" alt="" />
                                        <div className="feature-one__icon-shape">
                                            <img src="assets/images/shapes/feature-one-icon-shape.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="feature-one__title-box">
                                        <div className="feature-one__title-border"></div>
                                        <h3 className="feature-one__title"><a href={onclick = "return: false"}>Our Creed</a></h3>
                                    </div>
                                    <div className="feature-one__text">
                                        {this.props.LoadingFlag ? <img src={LoadingGif} alt="" height={150} width={240} /> :
                                            this.props.Creed !== "" ? <div dangerouslySetInnerHTML={{ __html: this.props.Creed }}></div> : "NA"}
                                    </div>
                                    {/* <div className="feature-one__btn-box">
                                        <a href="plumbing-services.html" className="feature-one__btn">View more</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--Feature One End--> */}
            </>
        );
    }
}