import React,{Component} from "react";
import { Helmet } from "react-helmet";
// import ContactOneSection from "../components/contact/ContactOneSection";
import ContactPage from "../components/contact/ContactPage";
import GoogleMap from "../components/contact/GoogleMap";
// import InformationSection from "../components/InformationSection";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";

export default class Contact extends Component{
    componentDidMount() {
        console.log("componentDidMount called");
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <>
            <Helmet>
                <title>Contact</title>
                    {/* <meta name='description' content='Home' /> */}
            </Helmet>
            <Layout>
                <PageHeader pageTitle="Contact" pageText="Contact us" />
                <ContactPage/>
                {/* <ContactOneSection/> */}
                <GoogleMap/>
                {/* <InformationSection/> */}
            </Layout>
            </>
        )
    }
}