import React, { Component } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default class ZonePage extends Component {
    render() {
        return (
            <>
                {/* <!--Team Page Start--> */}
                <section className="team-page">
                    <div className="container">
                        <div className="row">
                            {this.props.members.map((member, index) => (
                                <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" key={index}>
                                    <div className="team-one__single">
                                        <div className="team-one__img">
                                            <LazyLoadImage src={member.ProfilePic} alt="" width="50px" height="450px" />
                                            <ul className="list-unstyled team-one__social">
                                                <li><a href={onclick = "return: false"}><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href={onclick = "return: false"}><i className="fab fa-twitter"></i></a></li>
                                                <li><a href={onclick = "return: false"}><i className="fab fa-linkedin-in"></i></a></li>
                                                <li><a href={onclick = "return: false"}><i className="fab fa-dribbble"></i></a></li>
                                            </ul>
                                        </div>
                                        <div className="team-one__content">
                                            <div className="team-one__title-box">
                                                <h3 className="team-one__name"><Link to={"/profile?id=" + member.MemberId}>{member.MemberName}</Link></h3>
                                                <ul className="list-unstyled team-one__social-two">
                                                    <li><a href={onclick = "return: false"}><i className="fas fa-share-alt"></i></a></li>
                                                </ul>
                                            </div>
                                            <div className="team-one__sub-title-box">
                                                <p className="team-one__sub-title">{member.DesignationName}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* <ListContent /> */}
                </section>
                {/* <!--Team Page End--> */}
            </>
        )
    }
}